import NotificationIcon from 'Component/Icons/NotificationIcon/NotificationIcon';
import ImageCustom from 'Component/ImageCustom/ImageCustom';
import { readNotificationAction } from 'Screen/Notifications/slice/notificationSlice';
import { FORMAT_DATE_TIME_NOTIFICATION, getDateTimeString } from 'helpers/date';
import { useAppDispatch } from 'index';
import { translations } from 'locales/translations';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from 'types/RootState';
import c from 'clsx';
import { Epath } from 'routes/routesConfig';

type Props = {};

const NotificationsDropdown = (props: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { notificationsUnread, unreadNotiNumber } = useSelector(
    (state: RootState) => state.notification,
  );

  const onRead = (id: number) => {
    dispatch(readNotificationAction(id));
  };

  return (
    <Dropdown className="dropdown notifications mx-2 mx-lg-3">
      <Dropdown.Toggle
        as="s"
        className="nav-link dropdown-toggle pulse"
        role="button"
        data-bs-toggle="dropdown"
      >
        <NotificationIcon />
        {!!unreadNotiNumber && <span className="pulse-ring"></span>}
      </Dropdown.Toggle>
      <Dropdown.Menu
        id="NotificationsDiv"
        className="dropdown-menu rounded-lg shadow border-0 dropdown-animation dropdown-menu-md-end p-0 m-0"
      >
        <div className="card border-0 w380 card-notification">
          <div className="card-header border-0 p-3">
            <h5 className="mb-0 font-weight-light d-flex justify-content-between">
              <span>{t(translations.PAGE.NOTIFICATIONS.NOTIFICATIONS_UNREAD)}</span>
              {!!unreadNotiNumber && <span className="badge text-white">{unreadNotiNumber}</span>}
            </h5>
          </div>
          <div className="tab-content card-body p-0">
            <div className="tab-pane fade show active">
              <ul className="list-group list-unstyled mb-0">
                {notificationsUnread.length ? (
                  notificationsUnread.map((notification) => (
                    <Dropdown.Item
                      as="li"
                      className={c('list-group-item p-0 m-0', notification.read ? 'read' : '')}
                      onClick={() => notification.id && onRead(notification.id)}
                      key={notification.id}
                    >
                      <Link to={notification.link ?? '#'} className="d-flex px-md-3 py-2 py-md-3">
                        <ImageCustom
                          className="avatar rounded"
                          src={notification.image}
                          alt={notification.title}
                        />
                        <div className="flex-fill ms-3 text-truncate">
                          <h6 className="d-flex justify-content-between mb-0">
                            <span>{notification.title}</span>
                            <small className="msg-time">
                              {getDateTimeString(
                                notification.createdDate,
                                FORMAT_DATE_TIME_NOTIFICATION,
                              )}
                            </small>
                          </h6>
                          <span className="text-muted">{notification.content}</span>
                        </div>
                      </Link>
                    </Dropdown.Item>
                  ))
                ) : (
                  <h6 className="text-warning my-4 text-center">
                    {t(translations.COMMON.NO_RECORDS_TABLE)}
                  </h6>
                )}
              </ul>
            </div>
          </div>
          <Dropdown.Item as="div" className="card-footer text-center border-top-0 p-0">
            <Link to={Epath.notificationsPage} className="d-block w-100 h-100 p-2">
              {t(translations.PAGE.NOTIFICATIONS.VIEW_ALL_NOTIFICATIONS)}
            </Link>
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NotificationsDropdown;
