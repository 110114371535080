import { TCoinPair } from 'types/coin';

let subscriberCoinPair: any = null;

export const subscribeCoinPair = (dispatch: any, connection: any, stompClient: any) => {
  connection?.then(() => {
    subscriberCoinPair = stompClient?.subscribe('/topic/coin-pair', (data: any) => {
      const coinPair = JSON.parse(data.body);
      // dispatch && dispatch(setCoinPair(coinPair));
    });
  });
};

export const unsubscribeCoinPair = () => {
  if (subscriberCoinPair !== null) {
    subscriberCoinPair?.unsubscribe();
  }
};
