import moment from 'moment';

export const FORMAT_DATE_DEFAULT = 'YYYY/MM/DD';
export const FORMAT_DATE_TIME_DEFAULT = 'MM-DD HH:mm:ss';

export const FORMAT_DATE_TIME_TRANSACTION_HISTORY = 'YYYY-MM-DD HH:mm';
export const FORMAT_DATE_TIME_PAYMENT_HISTORY = 'YYYY-MM-DD HH:mm';
export const FORMAT_DATE_TIME_NOTIFICATION = 'HH:mm MM-DD';

export const getDateString = (date?: Date | string, format?: string) =>
  date ? moment(date).format(format ?? FORMAT_DATE_DEFAULT) : '';

export const getDateTimeString = (date?: Date | string, format?: string) =>
  date ? moment(date).format(format ?? FORMAT_DATE_TIME_DEFAULT) : '';
