import axios from 'axios';
import { axiosClient } from '../axiosClient';
import { TBuyCrypto, TTransferCoin } from 'Screen/WalletPage/slice/walletTypes';
import queryString from 'query-string';
import { TOptionsQuery } from 'types/common';
import { TPaymentHistory, TTransactionHistory } from 'types/transaction';

const walletApi = {
  getUserWallets: () => {
    const url = 'user-wallets/me';
    return axiosClient.get(url);
  },
  // UpdateBalances
  getUpdateBalances: (btcAddress: string) => {
    const basURL = process.env.REACT_APP_API_UPDATE_BALANCE_URL;
    const url = `${basURL}addrs/${btcAddress}`;
    return axios.get(url);
  },
  updateBalancesToServer: (isBTC = false) => {
    const url = 'user-wallets/update-balances';
    return axiosClient.get(isBTC ? url + '?isBTC=true' : url);
  },
  transferCoin: (data: TTransferCoin) => {
    const { recipient, ...rest } = data;
    const url = `user-wallets/withdraw/${recipient}`;
    const query = queryString.stringify(rest);
    return axiosClient.post(`${url}?${query}`);
  },
  getTransactionHistories: (params: TOptionsQuery<TTransactionHistory>) => {
    const url = 'transaction-histories/me';
    const query = queryString.stringify(params);
    return axiosClient.get(`${url}?${query}`);
  },
  getPaymentHistories: (params: TOptionsQuery<TPaymentHistory>) => {
    const url = 'payment-histories/me';
    const query = queryString.stringify(params);
    return axiosClient.get(`${url}?${query}`);
  },
  payment: (data: TBuyCrypto) => {
    const url = 'payment';
    return axiosClient.post(url, data);
  },
};

export default walletApi;
