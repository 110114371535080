import queryString from 'query-string';
import { Location } from 'react-router';
import { TUpdateUserRequest } from 'Screen/Auth/slice/authTypes';
import { IndexedObject } from 'types/common';
import { UserDetail } from 'types/user';
import {
  DEFAULT_FIXED_PRICE_USD,
  DEFAULT_MAXIMUM_FRACTION_DIGITS_NUMBER,
  isEmptyObject,
} from './common';
import { TFunction } from 'react-i18next';
import { TPaymentStatus, TTransactionStatus } from 'types/transaction';
import { translations } from 'locales/translations';
import { ChangeEvent } from 'react';

export const omitObject = (obj: IndexedObject, keys: Array<any>): IndexedObject => {
  if (!keys.length) return obj;
  const { [keys.pop()]: omitted, ...rest } = obj;
  return omitObject(rest, keys);
};

export const replacePathParams = (path: string, params: IndexedObject<string>): string =>
  path.replace(/:([^/]+)/g, (_, p1) => encodeURIComponent(params[p1] ? params[p1] : ''));

export const parseFloatNum = (str: string) => {
  const trimmed = str && typeof str.trim === 'function' ? str.trim() : null;
  if (!trimmed) {
    return null;
  }
  const num = parseFloat(trimmed);
  const isNumber = !isNaN(num);
  const isFullyParsedNum = isNumber && num.toString() === trimmed;
  return isFullyParsedNum ? num : null;
};
export const parseQueryParams = (search: string) => {
  const params = queryString.parse(search);
  return Object.keys(params).reduce((result: IndexedObject, key) => {
    const val = params[key];
    if (val === 'true') {
      result[key] = true;
    } else if (val === 'false') {
      result[key] = false;
    } else {
      const num = parseFloatNum(val ? val.toString() : '');
      result[key] = num === null ? val : num;
    }
    return result;
  }, {});
};

export const getHashLocation = (location: Location) =>
  location.hash ? location.hash.replace('#', '') : '';

export const createQueryUrl = (location: IndexedObject, params: IndexedObject) => {
  const { pathname } = location;
  if (isEmptyObject(params)) return pathname;
  const query = queryString.stringify(params);
  return `${pathname}?${query}`;
};

export const percentCal = (cur: number | string, total: number | string, fixed: number = 0) => {
  if (!cur || !total) return 0;
  const per = (parseFloat(cur.toString()) / parseFloat(total.toString())) * 100;
  return per.toFixed(fixed);
};

export const openUrlInNewTab = (url?: string) => {
  url && window.open(url, '_blank')?.focus();
};

export const isImage = (url: string) => {
  return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
};

export const copyTextToClipboard = (text: string): boolean => {
  let textArea = document.createElement('textarea');
  textArea.style.position = 'fixed';
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.width = '2em';
  textArea.style.height = '2em';
  textArea.style.padding = '0';
  textArea.style.border = 'none';
  textArea.style.outline = 'none';
  textArea.style.boxShadow = 'none';
  textArea.style.background = 'transparent';

  textArea.value = text;

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
    document.body.removeChild(textArea);
    return true;
  } catch (err) {
    document.body.removeChild(textArea);
    return false;
  }
};

const isObject = (object: any) => {
  return object !== null && typeof object === 'object';
};
export const isDeepEqualObject = (object1: IndexedObject, object2: IndexedObject) => {
  if (!isObject(object1) || !isObject(object2)) return false;
  const objKeys1 = Object.keys(object1);
  const objKeys2 = Object.keys(object2);

  if (objKeys1.length !== objKeys2.length) return false;

  for (const key of objKeys1) {
    const value1 = object1[key];
    const value2 = object2[key];

    const isObjects = isObject(value1) && isObject(value2);

    if ((isObjects && !isDeepEqualObject(value1, value2)) || (!isObjects && value1 !== value2)) {
      return false;
    }
  }
  return true;
};

export const reOrderArray: (list: any[], startIndex: number, endIndex: number) => any[] = (
  list: any[],
  startIndex: number,
  endIndex: number,
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const bookmarkPage = (otherBrowsersFunction: () => void) => {
  if (!!(window as any).sidebar && (window as any).sidebar.addPanel) {
    // Mozilla Firefox
    (window as any).sidebar.addPanel(document.title, window.location.href, '');
  } else if (window.external && 'AddFavorite' in window.external) {
    // Internet Explorer
    (window.external as any).AddFavorite(window.location.href, document.title);
  } else if (!!(window as any).opera && !!window.print) {
    // Opera
    let bookmark = document.createElement('a');
    bookmark.setAttribute('href', window.location.href);
    bookmark.setAttribute('title', document.title);
    bookmark.setAttribute('rel', 'sidebar');
    bookmark.click();
  } else {
    // Other browsers
    // Show notification
    // 'Press ' + (navigator.userAgent.toLowerCase().indexOf('mac') != -1 ? 'Command/Cmd' : 'CTRL') + '+D to bookmark this page.'
    otherBrowsersFunction();
  }
};

export const getObjectUserCanUpdate = (user: UserDetail): TUpdateUserRequest => ({
  login: user.login ?? '',
  firstName: user.firstName,
  lastName: user.lastName,
  langKey: user.langKey,
});

export const convertToURLString = (text?: string) => {
  return text ? text.replace('/', '__') : '';
};

export const convertStringFormURL = (text?: string) => {
  return text ? text.replace('__', '/') : '';
};

export const random6CodeString = () =>
  Math.floor(Math.random() * 1000000)
    .toString()
    .padStart(6, '0');

export const formatterPriceUSD = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: DEFAULT_FIXED_PRICE_USD,
});

export const formattedNumber = (number: number = 0) =>
  Number(number ?? 0).toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: DEFAULT_MAXIMUM_FRACTION_DIGITS_NUMBER,
  });

export const shortenedStr = (str?: string, numberCharactersPrev: number = 4) =>
  !str
    ? ''
    : str.length > numberCharactersPrev * 2 + 3
    ? `${str.slice(0, numberCharactersPrev)}...${str.slice(0 - numberCharactersPrev)}`
    : str;

export const getTransactionStatus = (
  status: TTransactionStatus = 'FAILURE',
  t?: TFunction<'translation', undefined>,
) => {
  switch (status) {
    case 'IN_PROCESS':
      return {
        name: t ? t(translations.COMMON.TRANSACTION_STATUS.IN_PROCESS) : status,
        className: 'text-warning',
      };
    case 'FAILURE':
      return {
        name: t ? t(translations.COMMON.TRANSACTION_STATUS.FAILURE) : status,
        className: 'text-danger',
      };
    case 'SUCCESS':
      return {
        name: t ? t(translations.COMMON.TRANSACTION_STATUS.SUCCESS) : status,
        className: 'text-success',
      };
    default:
      return {
        name: t ? t(translations.COMMON.TRANSACTION_STATUS.IN_PROCESS) : status,
        className: 'text-warning',
      };
  }
};

export const getPaymentStatus = (
  status: TPaymentStatus = 'FAILURE',
  t?: TFunction<'translation', undefined>,
) => {
  switch (status) {
    case 'IN_PROCESS':
      return {
        name: t ? t(translations.COMMON.PAYMENT_STATUS.IN_PROCESS) : status,
        className: 'text-warning',
      };
    case 'FAILURE':
      return {
        name: t ? t(translations.COMMON.PAYMENT_STATUS.FAILURE) : status,
        className: 'text-danger',
      };
    case 'SUCCESS':
      return {
        name: t ? t(translations.COMMON.PAYMENT_STATUS.SUCCESS) : status,
        className: 'text-success',
      };
    default:
      return {
        name: t ? t(translations.COMMON.PAYMENT_STATUS.IN_PROCESS) : status,
        className: 'text-warning',
      };
  }
};
