import { setCoinTrend } from 'Screen/Market/slice/marketSlice';
import { TCoinTrend } from 'types/coin';

let subscriberCoinTrend: any = null;

export const subscribeCoinTrend = (dispatch: any, connection: any, stompClient: any) => {
  connection?.then(() => {
    subscriberCoinTrend = stompClient?.subscribe('/topic/coin-trend', (data: any) => {
      const coinTrend = JSON.parse(data.body) as TCoinTrend;
      dispatch && dispatch(setCoinTrend(coinTrend));
    });
  });
};

export const unsubscribeCoinTrend = () => {
  if (subscriberCoinTrend !== null) {
    subscriberCoinTrend?.unsubscribe();
  }
};
