import React from 'react';
import { Link } from 'react-router-dom';
import { Epath } from 'routes/routesConfig';
import Logo from 'assets/images/logo.png';
import { useSelector } from 'react-redux';
import { RootState } from 'types/RootState';

type Props = {
  height?: number;
};

const LogoHeader = ({ height = 30 }: Props) => {
  const { authenticated } = useSelector((state: RootState) => state.auth);
  return (
    <Link to={authenticated ? Epath.dashboard : Epath.top} className="d-flex align-item-center">
      <img src={Logo} alt="Fruits" className="logo-image" style={{ height: `${height}px` }} />
    </Link>
  );
};

export default LogoHeader;
