import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import authApi from 'axiosApi/api/authApi';
import userApi from 'axiosApi/api/userApi';
import {
  getResLoginObjectFromLS,
  getUserFromLocalStorage,
  removeFcmToken,
  removeRefreshToken,
  removeResLoginObjectLS,
  removeToken,
  removeUser,
  saveToken,
  saveUser,
} from 'helpers/localStorage';
import { translations } from 'locales/translations';
import { addToast } from 'Redux/Reducers/toastSlice';
import { Epath } from 'routes/routesConfig';
import { TActionParams, TErrorApiResponse } from 'types/common';
import { RootState } from 'types/RootState';
import { TUserWallet, UserDetail } from 'types/user';
import { createSlice } from 'utils/@reduxjs/toolkit';
import {
  AuthState,
  TActiveAccountRequest,
  TForgotPassRequest,
  TRegisterRequest,
  TResetPassword,
  TResponseLogin,
  TSendOTP,
  TSignInRequest,
  TUpdateUserRequest,
  TVerifyOTP,
} from './authTypes';
import { PIN_CODE_KEY } from 'Screen/SecurityPage/SecurityPage';

const initialState: AuthState = {
  authenticated: false,
  loadingAuth: false,
  currentUser: {},
  responseLogin: getResLoginObjectFromLS(),
};

export const getCurrentUser = createAsyncThunk('auth/getCurrentUser', async (_, { dispatch }) => {
  await userApi
    .getCurrentUser()
    .then((res) => {
      const dataUser: UserDetail = res.data.user
        ? {
            phoneNumber: res.data?.phoneNumber,
            ...res.data.user,
          }
        : {};
      dispatch(setCurrentUser(dataUser));
    })
    .catch(() => dispatch(logOut()));
});

export const updateCurrentUser = createAsyncThunk(
  'auth/updateCurrentUser',
  async (actionParams: TActionParams<TUpdateUserRequest>, { dispatch }) => {
    dispatch(setLoadingAuth(true));
    const { t, data, funct } = actionParams;
    await userApi
      .updateCurrentUser(data)
      .then(() => {
        dispatch(getCurrentUser());
        funct && funct();
        t &&
          dispatch(
            addToast({
              message: t(translations.NOTIFICATION.SAVE_SUCCESS_DEFAULT),
              type: 'success',
            }),
          );
      })
      .catch((err: AxiosError<TErrorApiResponse>) => {
        t &&
          dispatch(
            addToast({
              message:
                err.response?.data?.title ||
                err.response?.data?.toString() ||
                err.message ||
                t(translations.NOTIFICATION.ERROR_DEFAULT),
              type: 'danger',
            }),
          );
      });
    dispatch(setLoadingAuth(false));
  },
);

export const loginAction = createAsyncThunk(
  'auth/loginAction',
  async (actionParams: TActionParams<TSignInRequest>, { dispatch }) => {
    dispatch(setLoadingAuth(true));
    const { navigate, t, data } = actionParams;
    await authApi
      .login(data)
      .then((res) => {
        const dataRes = res.data as unknown as TResponseLogin;

        // Bypass OTP
        dispatch(logIn(dataRes));
        dispatch(getCurrentUser());
        t &&
          dispatch(
            addToast({
              message: t(translations.NOTIFICATION.AUTH.LOGIN_SUCCESS),
              type: 'success',
            }),
          );
        navigate && navigate(Epath.dashboard);

        // if (dataRes.phoneNumber || dataRes.mail) {
        //   dispatch(setResponseLogin(dataRes));
        //   dispatch(
        //     sendOTP({
        //       data: {
        //         phoneNumber: dataRes.phoneNumber,
        //         type: 0,
        //       },
        //       navigate,
        //       t,
        //     }),
        //   );
        // } else {
        //   t &&
        //     dispatch(
        //       addToast({
        //         message: t(translations.NOTIFICATION.ERROR_DEFAULT),
        //         type: 'danger',
        //       }),
        //     );
        // }
      })
      .catch((err: AxiosError<TErrorApiResponse>) => {
        t &&
          dispatch(
            addToast({
              message:
                err.response?.data?.title ||
                err.response?.data?.toString() ||
                err.message ||
                t(translations.NOTIFICATION.ERROR_DEFAULT),
              type: 'danger',
            }),
          );
      });
    dispatch(setLoadingAuth(false));
  },
);

export const registerAction = createAsyncThunk(
  'auth/registerAction',
  async (actionParams: TActionParams<TRegisterRequest>, { dispatch }) => {
    dispatch(setLoadingAuth(true));
    const { navigate, t, data } = actionParams;
    await authApi
      .register(data)
      .then(() => {
        t &&
          dispatch(
            addToast({
              message: t(translations.NOTIFICATION.AUTH.REGISTER_SUCCESS),
              type: 'success',
            }),
          );
        navigate && navigate(Epath.auth.login);
      })
      .catch((err: AxiosError<TErrorApiResponse>) => {
        t &&
          dispatch(
            addToast({
              message:
                err.response?.data?.title ||
                err.response?.data?.toString() ||
                err.message ||
                t(translations.NOTIFICATION.ERROR_DEFAULT),
              type: 'danger',
            }),
          );
      });
    dispatch(setLoadingAuth(false));
  },
);

export const forgotPasswordAction = createAsyncThunk(
  'auth/forgotPasswordAction',
  async (actionParams: TActionParams<TForgotPassRequest>, { dispatch }) => {
    dispatch(setLoadingAuth(true));
    const { navigate, t, data } = actionParams;
    await authApi
      .forgotPassword(data)
      .then(() => {
        t &&
          dispatch(
            addToast({
              message: t(translations.NOTIFICATION.AUTH.FORGOT_PASSWORD_SUCCESS),
              type: 'success',
            }),
          );
        navigate && navigate(Epath.auth.resetPassword);
      })
      .catch((err: AxiosError<TErrorApiResponse>) => {
        t &&
          dispatch(
            addToast({
              message:
                err.response?.data?.title ||
                err.response?.data?.toString() ||
                err.message ||
                t(translations.NOTIFICATION.ERROR_DEFAULT),
              type: 'danger',
            }),
          );
      });
    dispatch(setLoadingAuth(false));
  },
);

export const resetPasswordAction = createAsyncThunk(
  'auth/resetPasswordAction',
  async (actionParams: TActionParams<TResetPassword>, { dispatch }) => {
    dispatch(setLoadingAuth(true));
    const { navigate, t, data } = actionParams;
    await authApi
      .resetPassword(data)
      .then(() => {
        t &&
          dispatch(
            addToast({
              message: t(translations.NOTIFICATION.AUTH.RESET_PASSWORD_SUCCESS),
              type: 'success',
            }),
          );
        navigate && navigate(Epath.auth.login);
      })
      .catch((err: AxiosError<TErrorApiResponse>) => {
        t &&
          dispatch(
            addToast({
              message:
                err.response?.data?.title ||
                err.response?.data?.toString() ||
                err.message ||
                t(translations.NOTIFICATION.ERROR_DEFAULT),
              type: 'danger',
            }),
          );
        navigate && navigate(Epath.auth.forgotPassword);
      });
    dispatch(setLoadingAuth(false));
  },
);

export const sendOTP = createAsyncThunk(
  'auth/sendOTP',
  async (actionParams: TActionParams<TSendOTP>, { dispatch, getState }) => {
    dispatch(setLoadingAuth(true));
    const { navigate, t, data } = actionParams;
    await authApi
      .sendOTP(data)
      .then((res) => {
        const dataRes = res.data as unknown as TResponseLogin;
        dispatch(setResponseLogin(dataRes));
        navigate && navigate(Epath.auth.verification);
        t &&
          dispatch(
            addToast({
              message: t(translations.NOTIFICATION.AUTH.SEND_OTP_SUCCESS),
              type: 'success',
            }),
          );
      })
      .catch((err: AxiosError<TErrorApiResponse>) => {
        t &&
          dispatch(
            addToast({
              message:
                err.response?.data?.title ||
                err.response?.data?.toString() ||
                err.message ||
                t(translations.NOTIFICATION.ERROR_DEFAULT),
              type: 'danger',
            }),
          );
      });
    dispatch(setLoadingAuth(false));
  },
);

export const verifyOTP = createAsyncThunk(
  'auth/verifyOTP',
  async (actionParams: TActionParams<TVerifyOTP>, { dispatch, getState }) => {
    dispatch(setLoadingAuth(true));
    const { responseLogin } = (getState() as RootState).auth;
    const { navigate, t, data } = actionParams;

    await authApi
      .verifyOTP(data)
      .then(() => {
        dispatch(logIn(responseLogin));
        dispatch(getCurrentUser());
        dispatch(removeResponseLogin());
        t &&
          dispatch(
            addToast({
              message: t(translations.NOTIFICATION.AUTH.VERIFY_OTP_SUCCESS),
              type: 'success',
            }),
          );
        navigate && navigate(Epath.dashboard);
      })
      .catch((err: AxiosError<TErrorApiResponse>) => {
        t &&
          dispatch(
            addToast({
              message:
                err.response?.data?.title ||
                err.response?.data?.toString() ||
                err.message ||
                t(translations.NOTIFICATION.ERROR_DEFAULT),
              type: 'danger',
            }),
          );
      });
    dispatch(setLoadingAuth(false));
  },
);

export const updatePIN = createAsyncThunk(
  'auth/updatePIN',
  async (actionParams: TActionParams<{ pin: string }>, { dispatch, getState }) => {
    dispatch(setLoadingAuth(true));
    const { t, data } = actionParams;
    await new Promise(function (resolve) {
      setTimeout(() => {
        data.pin
          ? localStorage.setItem(PIN_CODE_KEY, data.pin)
          : localStorage.removeItem(PIN_CODE_KEY);
        resolve(true);
      }, 1000);
    })
      .then(() => {
        t &&
          dispatch(
            addToast({
              message: t(translations.NOTIFICATION.SAVE_SUCCESS_DEFAULT),
              type: 'success',
            }),
          );
      })
      .catch((err: AxiosError<TErrorApiResponse>) => {
        t &&
          dispatch(
            addToast({
              message:
                err.response?.data?.title ||
                err.response?.data?.toString() ||
                err.message ||
                t(translations.NOTIFICATION.ERROR_DEFAULT),
              type: 'danger',
            }),
          );
      });
    dispatch(setLoadingAuth(false));
  },
);

export const activeAccount = createAsyncThunk(
  'auth/activeAccount',
  async (actionParams: TActionParams<TActiveAccountRequest>, { dispatch }) => {
    dispatch(setLoadingAuth(true));
    const { t, data, navigate } = actionParams;
    await userApi
      .activeAccount(data)
      .then(() => {
        t &&
          dispatch(
            addToast({
              message: t(translations.NOTIFICATION.AUTH.ACTIVE_ACCOUNT_SUCCESS),
              type: 'success',
            }),
          );
      })
      .catch((err: AxiosError<TErrorApiResponse>) => {
        t &&
          dispatch(
            addToast({
              message:
                err.response?.data?.title ||
                err.response?.data?.toString() ||
                err.message ||
                t(translations.NOTIFICATION.ERROR_DEFAULT),
              type: 'danger',
            }),
          );
      });
    dispatch(setLoadingAuth(false));
    navigate && navigate(Epath.auth.login);
  },
);

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoadingAuth(state, action: PayloadAction<boolean>) {
      state.loadingAuth = action.payload;
    },
    setResponseLogin(state, action: PayloadAction<TResponseLogin>) {
      state.responseLogin = {
        ...state.responseLogin,
        ...action.payload,
      };
    },
    removeResponseLogin(state) {
      state.responseLogin = {};
    },
    logIn(state, action: PayloadAction<TResponseLogin>) {
      state.loadingAuth = false;
      state.authenticated = true;
      saveToken(action.payload.id_token);
      // saveRefreshToken(action.payload.id_token);
      removeResLoginObjectLS();
    },
    logOut(state) {
      state.authenticated = false;
      state.currentUser = {};
      removeToken();
      removeRefreshToken();
      removeUser();
      removeFcmToken();
      removeFcmToken();
    },
    setAuth(state) {
      state.authenticated = true;
      state.currentUser = getUserFromLocalStorage();
    },
    setCurrentUser(state, action: PayloadAction<UserDetail>) {
      state.currentUser = action.payload;
      saveUser(action.payload);
    },
  },
});

export const { actions, reducer: authReducer } = slice;
export const {
  logOut,
  setAuth,
  setCurrentUser,
  setLoadingAuth,
  logIn,
  setResponseLogin,
  removeResponseLogin,
} = actions;
export default authReducer;
