/**
 * Create the store with dynamic reducers
 */

import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { createReducer, rootReducers } from './Reducers/Index';
import websocketMiddleware from './middlewares/webSocket/websocket-middleware';

export function configureAppStore() {
  const customizedMiddleware = getDefaultMiddleware({
    serializableCheck: false,
  });

  const store = configureStore({
    reducer: createReducer(rootReducers),
    middleware: [...customizedMiddleware, websocketMiddleware],
    devTools: process.env.NODE_ENV !== 'production',
  });

  return store;
}
