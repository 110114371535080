import { TDataTheme, TThemeColor, TThemeObject } from 'types/theme';

export const DEFAULT_COLOR_THEME: TThemeColor = 'orange';
export const DEFAULT_DATA_THEME: TDataTheme = 'light';
export const DEFAULT_RTL_MODE: boolean = false;

export const DEFAULT_THEME_OBJECT: TThemeObject = {
  color: DEFAULT_COLOR_THEME,
  data: DEFAULT_DATA_THEME,
  rtl: DEFAULT_RTL_MODE,
};
