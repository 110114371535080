import SpinnerLoading from 'Component/Loading/SpinnerLoading';
import ModalPinCode from 'Component/SecurityPage/ModalPinCode';
import { useAppDispatch } from 'index';
import { translations } from 'locales/translations';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'types/RootState';
import PageTitle from '../../Component/Comman/PageTitle';

export const PIN_CODE_KEY = 'pinCode';
export type TActionPIN = 'disable' | 'change' | 'new' | '';

function SecurityPage() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { currentUser, loadingAuth } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const [modalPin, setModalPin] = useState<TActionPIN>('');
  const [changeModel, setChangeModel] = useState(false);
  const [removeModel, setRemoveModel] = useState(false);
  const [changePasswordModel, setChangePasswordModel] = useState(false);

  const pinCode = localStorage.getItem(PIN_CODE_KEY) ?? '';

  return (
    <>
      <div className="body-header border-bottom d-flex py-3 mb-3">
        <PageTitle pagetitle={t(translations.PAGE.SECURITY.TITLE)} sidebutton={true} />
      </div>
      <div className="container-xxl">
        <div className="col-xl-12">
          <div className="card">
            <SpinnerLoading loading={loadingAuth}>
              <div className="card-body">
                {/* <div className="border-bottom py-2 mb-2">
                  <div className="row justify-content-between">
                    <div className="col-lg-4 col-xl-4">
                      <div className="d-flex">
                        <i className="icofont-key fs-5 text-primary"></i>
                        <div className="d-flex flex-column px-2">
                          <span className="fw-bold">
                            {t(translations.PAGE.SECURITY.AUTHENTICATION.PIN_CODE)}
                          </span>
                          <span className="text-muted small">
                            {t(translations.PAGE.SECURITY.AUTHENTICATION.PIN_CODE_SUB_TITLE)}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-xl-4">
                      <div className="d-flex align-items-center">
                        {pinCode ? (
                          <>
                            <i className="icofont-check-circled fs-5 text-success"></i>
                            <span className="px-2">******</span>
                          </>
                        ) : (
                          <>
                            <i className="icofont-close-circled fs-5 text-danger"></i>
                            <span className="px-2">{t(translations.COMMON.UNSET)}</span>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4 col-xl-4">
                      <button
                        type="button"
                        className="btn flex-fill btn-light-success py-2 fs-6 text-uppercase px-3 mt-2 mt-lg-0 float-lg-end"
                        onClick={() => {
                          setModalPin(pinCode ? 'change' : 'new');
                        }}
                      >
                        {t(translations.COMMON[pinCode ? 'CHANGE' : 'ENABLE'])}
                      </button>
                      {!!pinCode && (
                        <button
                          type="button"
                          className="btn flex-fill btn-light-danger py-2 fs-6 text-uppercase px-3 mt-2 mt-lg-0 float-lg-end mx-2"
                          onClick={() => {
                            setModalPin('disable');
                          }}
                        >
                          {t(translations.COMMON.DISABLE)}
                        </button>
                      )}
                    </div>
                  </div>
                </div> */}
                {/* <div className="border-bottom py-2 mb-2">
                <div className="row justify-content-between">
                  <div className="col-lg-4 col-xl-4">
                    <div className="d-flex">
                      <i className="icofont-ui-lock fs-5 text-primary"></i>
                      <div className="d-flex flex-column px-2">
                        <span className="fw-bold">
                          {' '}
                          {t(translations.PAGE.SECURITY.AUTHENTICATION.LOGIN_PASSWORD)}
                        </span>
                        <span className="text-muted small">
                          {t(translations.PAGE.SECURITY.AUTHENTICATION.LOGIN_PASSWORD_SUB_TITLE)}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-xl-4">
                    <button
                      type="button"
                      className="btn flex-fill btn-light-success py-2 fs-6 text-uppercase px-3 mt-2 mt-lg-0 float-lg-end"
                      onClick={() => {
                        setChangeModel(true);
                      }}
                    >
                      {t(translations.COMMON.CHANGE)}
                    </button>
                  </div>
                </div>
              </div> */}
                <div className="border-bottom py-2 mb-2">
                  <div className="row justify-content-between">
                    <div className="col-lg-4 col-xl-4">
                      <div className="d-flex">
                        <i className="icofont-smart-phone fs-5 text-primary"></i>
                        <div className="d-flex flex-column px-2">
                          <span className="fw-bold">
                            {t(translations.PAGE.SECURITY.AUTHENTICATION.PHONE_NUMBER_VERIFICATION)}
                          </span>
                          <span className="text-muted small">
                            {t(translations.PAGE.SECURITY.AUTHENTICATION.AUTHENTICATION_SUB_TITLE)}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-xl-4">
                      <div className="d-flex align-items-center">
                        <i className="icofont-check-circled fs-5 text-success"></i>
                        <span className="px-2">{currentUser.phoneNumber}</span>
                      </div>
                    </div>
                    <div className="col-lg-4 col-xl-4">
                      {/* <button
                  type="button"
                  className="btn flex-fill btn-light-success py-2 fs-6 text-uppercase px-3 mt-2 mt-lg-0 float-lg-end mx-2"
                  onClick={() => {
                    setChangeModel(true);
                  }}
                >
                  Change
                </button> */}
                    </div>
                  </div>
                </div>
                <div className=" py-2 mb-2">
                  <div className="row justify-content-between">
                    <div className="col-lg-4 col-xl-4">
                      <div className="d-flex">
                        <i className="icofont-email fs-5 text-primary"></i>
                        <div className="d-flex flex-column px-2">
                          <span className="fw-bold">
                            {t(translations.PAGE.SECURITY.AUTHENTICATION.EMAIL_VERIFICATION)}
                          </span>
                          <span className="text-muted small">
                            {t(translations.PAGE.SECURITY.AUTHENTICATION.AUTHENTICATION_SUB_TITLE)}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-xl-4">
                      <div className="d-flex align-items-center">
                        <i className="icofont-check-circled fs-5 text-success"></i>
                        <span className="px-2">{currentUser.email}</span>
                      </div>
                    </div>
                    <div className="col-lg-4 col-xl-4">
                      {/* <button
                  type="button"
                  className="btn flex-fill btn-light-success py-2 fs-6 text-uppercase px-3 mt-2 mt-lg-0 float-lg-end mx-2"
                  onClick={() => {
                    setChangeModel(true);
                  }}
                >
                  Change
                </button> */}
                    </div>
                  </div>
                </div>
                {/* <div className="py-2">
                  <div className="row justify-content-between">
                    <div className="col-lg-4 col-xl-4">
                      <div className="d-flex">
                        <i className="icofont-trash fs-5 text-primary"></i>
                        <div className="d-flex flex-column px-2">
                          <span className="fw-bold">
                            {t(translations.PAGE.SECURITY.AUTHENTICATION.DELETE_ACCOUNT)}
                          </span>
                          <span className="text-muted small">
                            {t(translations.PAGE.SECURITY.AUTHENTICATION.DELETE_ACCOUNT_SUB_TITLE)}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-xl-4">
                      <button
                        type="button"
                        className="btn flex-fill btn-light-danger py-2 fs-6 text-uppercase px-3 mt-2 mt-lg-0 float-lg-end mx-2"
                        onClick={() => {
                          setModalPin('disable');
                        }}
                      >
                        {t(translations.COMMON.DELETE)}
                      </button>
                    </div>
                  </div>
                </div> */}
              </div>
            </SpinnerLoading>
          </div>
        </div>
      </div>
      {modalPin && (
        <ModalPinCode show={!!modalPin} onClose={() => setModalPin('')} action={modalPin} />
      )}
      <Modal
        className="modal"
        id="ChangeModal"
        style={{ display: 'block' }}
        show={changeModel}
        onHide={() => {
          setChangeModel(true);
        }}
      >
        <div className="modal-content">
          <Modal.Header className="modal-header" closeButton>
            <h5 className="modal-title">Change Settings</h5>
          </Modal.Header>
          <Modal.Body className="modal-body">
            <form>
              <div className="mb-3">
                <label className="form-label">Old Detail</label>
                <input type="text" className="form-control" />
              </div>
              <div className="mb-3">
                <label className="form-label">New Detail</label>
                <input type="text" className="form-control" />
              </div>
              <div className="mb-3 form-check">
                <input type="checkbox" className="form-check-input" />
                <label className="form-check-label">Check me out</label>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={() => {
                setChangeModel(false);
              }}
            >
              Close
            </button>
            <button type="button" className="btn btn-primary">
              Save Change
            </button>
          </Modal.Footer>
        </div>
      </Modal>
      <Modal
        className="modal"
        id="RemoveModal"
        style={{ display: 'block' }}
        show={removeModel}
        onHide={() => {
          setRemoveModel(false);
        }}
      >
        <div className="modal-content">
          <Modal.Header className="modal-header" closeButton>
            <h5 className="modal-title">Remove Settings</h5>
          </Modal.Header>
          <Modal.Body className="modal-body">
            <i className="icofont-ui-delete fs-2 text-danger d-flex justify-content-center"></i>
            <h2 className="my-3 text-center">Are you sure you want to remove?</h2>
            <p className="text-muted">
              Withdrawals and P2P selling will be disabled for 24 hours after you make this change
              to protect your account.
            </p>
            <p className="text-muted">
              Two security verification methods are required for withdrawals and other actions.
              Using only one verification method will limit your withdrawals.
            </p>
          </Modal.Body>
          <Modal.Footer className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => {
                setRemoveModel(false);
              }}
            >
              Close
            </button>
            <button type="button" className="btn btn-primary">
              Save Change
            </button>
          </Modal.Footer>
        </div>
      </Modal>
      <Modal
        className="modal"
        id="ChangeModal"
        style={{ display: 'block' }}
        show={changePasswordModel}
        onHide={() => {
          setChangePasswordModel(false);
        }}
      >
        <div className="modal-content">
          <Modal.Header className="modal-header" closeButton>
            <h5 className="modal-title">Change Settings</h5>
          </Modal.Header>
          <Modal.Body className="modal-body">
            <form>
              <div className="mb-3">
                <label className="form-label">Old Detail</label>
                <input type="text" className="form-control" />
              </div>
              <div className="mb-3">
                <label className="form-label">New Detail</label>
                <input type="text" className="form-control" />
              </div>
              <div className="mb-3 form-check">
                <input type="checkbox" className="form-check-input" />
                <label className="form-check-label">Check me out</label>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={() => {
                setChangePasswordModel(false);
              }}
            >
              Close
            </button>
            <button type="button" className="btn btn-primary">
              Save Change
            </button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
}

export default SecurityPage;
