import c from 'clsx';
import { DEFAULT_SIZE_SPINNER_LOADING } from 'helpers/common';
import { ReactElement } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import './SpinnerLoading.scss';

type Props = {
  children: ReactElement;
  loading: boolean;
  className?: string;
  size?: number;
};

const SpinnerLoading = ({
  children,
  loading,
  size = DEFAULT_SIZE_SPINNER_LOADING,
  className,
}: Props) => {
  return (
    <div className={c('position-relative spinner-loading-wrapper', className)}>
      {children}
      {loading && (
        <div className="position-absolute top-0 start-0 bottom-0 end-0 d-flex justify-content-center align-items-center overlay">
          <Spinner
            animation="border"
            variant="warning"
            style={{ width: `${size}px`, height: `${size}px` }}
          />
        </div>
      )}
    </div>
  );
};

export default SpinnerLoading;
