import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';
import walletApi from 'axiosApi/api/walletApi';
import {
  DEFAULT_PAGE,
  DEFAULT_SIZE,
  DEFAULT_TOTAL_RESULTS,
  defaultPagination,
} from 'helpers/common';
import { translations } from 'locales/translations';
import { addToast } from 'Redux/Reducers/toastSlice';
import { TActionParams, TErrorApiResponse, TOptionsQuery } from 'types/common';
import { RootState } from 'types/RootState';
import { TUserWallet } from 'types/user';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { TBuyCrypto, TLoadingWallet, TTransferCoin, WalletState } from './walletTypes';
import { TPaymentHistory, TTransactionHistory } from 'types/transaction';
import { loadStripe } from '@stripe/stripe-js';

export const DEFAULT_SORT_TRANSACTION_HISTORIES = 'createdDate,desc';
export const DEFAULT_SIZE_TRANSACTION_HISTORIES = 10;

export const DEFAULT_SORT_PAYMENT_HISTORIES = 'createdDate,desc';
export const DEFAULT_SIZE_PAYMENT_HISTORIES = 10;

const initialState: WalletState = {
  loadingWallet: [],

  // TransactionHistories
  paramsTransactionHistories: {},
  paginationTransactionHistories: defaultPagination,
  transactionHistories: [],

  // PaymentHistories
  paramsPaymentHistories: {},
  paginationPaymentHistories: defaultPagination,
  paymentHistories: [],

  userWallets: [],
};

export const getUserWallets = createAsyncThunk('wallet/getUserWallets', async (_, { dispatch }) => {
  await walletApi.getUserWallets().then((res) => dispatch(setUserWallets(res.data)));
});

export const getTransactionHistories = createAsyncThunk(
  'wallet/getTransactionHistories',
  async (params: TOptionsQuery<TTransactionHistory>, { dispatch, getState }) => {
    dispatch(setLoadingWallet('transactions'));
    dispatch(setParams(params));
    const state = (getState() as RootState).wallet;
    const {
      sort = state.paramsTransactionHistories.sort || DEFAULT_SORT_TRANSACTION_HISTORIES,
      size = state.paramsTransactionHistories.size || DEFAULT_SIZE_TRANSACTION_HISTORIES,
      page = state.paramsTransactionHistories.page || DEFAULT_PAGE,
    } = params ? params : {};
    let newParams: TOptionsQuery<TTransactionHistory> = Object.create(null);
    newParams.sort = sort;
    newParams.size = size;
    newParams.page = page - 1;
    const res = await walletApi.getTransactionHistories({ ...params, ...newParams });
    dispatch(setLoadingWallet('transactions'));
    return res;
  },
);

export const getPaymentHistories = createAsyncThunk(
  'wallet/getPaymentHistories',
  async (params: TOptionsQuery<TPaymentHistory>, { dispatch, getState }) => {
    dispatch(setLoadingWallet('paymentHistories'));
    dispatch(setParams(params));
    const state = (getState() as RootState).wallet;
    const {
      sort = state.paramsPaymentHistories.sort || DEFAULT_SORT_PAYMENT_HISTORIES,
      size = state.paramsPaymentHistories.size || DEFAULT_SIZE_PAYMENT_HISTORIES,
      page = state.paramsPaymentHistories.page || DEFAULT_PAGE,
    } = params ? params : {};
    let newParams: TOptionsQuery<TPaymentHistory> = Object.create(null);
    newParams.sort = sort;
    newParams.size = size;
    newParams.page = page - 1;
    const res = await walletApi.getPaymentHistories({ ...params, ...newParams });
    dispatch(setLoadingWallet('paymentHistories'));
    return res;
  },
);

export const getUpdateBalances = createAsyncThunk(
  'wallet/getUpdateBalances',
  async (btcAddress: string, { dispatch, getState }) => {
    // Update ETH, USDT, FRTS,...
    await walletApi.updateBalancesToServer().then((response) => {
      if (Array.isArray(response.data) && !!response.data.length) {
        const state = (getState() as RootState).wallet;
        dispatch(getUserWallets());
        dispatch(getTransactionHistories(state.paramsTransactionHistories));
      }
    });

    // Update BTC
    await walletApi.getUpdateBalances(btcAddress).then(async (res) => {
      const { userWallets } = (getState() as RootState).wallet;
      const btcWallet = userWallets.find((w) => w.cryptoCurrency?.symbol === 'BTC');
      if (
        btcWallet &&
        Number(res.data?.balance ?? 0) > Number(btcWallet.cryptoCurrency?.minDepBalance ?? 0)
      ) {
        await walletApi.updateBalancesToServer(true).then((response) => {
          if (Array.isArray(response.data) && !!response.data.length) {
            const state = (getState() as RootState).wallet;
            dispatch(getUserWallets());
            dispatch(getTransactionHistories(state.paramsTransactionHistories));
          }
          // dispatch(
          //   addToast({
          //     message: '',
          //     type: 'success',
          //   }),
          // );
        });
      }
    });
  },
);

export const transferCoin = createAsyncThunk(
  'wallet/transferCoin',
  async (actionParams: TActionParams<TTransferCoin>, { dispatch, getState }) => {
    dispatch(setLoadingWallet('transfer'));
    const { t, data, funct } = actionParams;
    await walletApi
      .transferCoin(data)
      .then(async () => {
        const { paramsTransactionHistories } = (getState() as RootState).wallet;
        dispatch(getUserWallets());
        dispatch(getTransactionHistories(paramsTransactionHistories));
        funct && funct();
        t &&
          dispatch(
            addToast({
              message: t(translations.NOTIFICATION.WALLET.TRANSFER.SUCCESS),
              type: 'success',
            }),
          );
      })
      .catch((err: AxiosError<TErrorApiResponse>) => {
        t &&
          dispatch(
            addToast({
              message:
                err.response?.data?.title ||
                err.response?.data?.toString() ||
                err.message ||
                t(translations.NOTIFICATION.WALLET.TRANSFER.ERROR),
              type: 'danger',
            }),
          );
      });
    dispatch(setLoadingWallet('transfer'));
  },
);

export const withdrawCoin = createAsyncThunk(
  'wallet/withdrawCoin',
  async (actionParams: TActionParams<TTransferCoin>, { dispatch, getState }) => {
    dispatch(setLoadingWallet('withdraw'));
    const { t, data, funct } = actionParams;
    await walletApi
      .transferCoin(data)
      .then(async (res) => {
        const { paramsTransactionHistories } = (getState() as RootState).wallet;
        dispatch(getUserWallets());
        dispatch(getTransactionHistories(paramsTransactionHistories));
        funct && funct();
        // t &&
        //   dispatch(
        //     addToast({
        //       message: t(translations.NOTIFICATION.WALLET.WITHDRAW.SUCCESS),
        //       type: 'success',
        //     }),
        //   );
      })
      .catch((err: AxiosError<TErrorApiResponse>) => {
        // t &&
        //   dispatch(
        //     addToast({
        //       message:
        //         err.response?.data?.title ||
        //         err.response?.data?.toString() ||
        //         err.message ||
        //         t(translations.NOTIFICATION.WALLET.WITHDRAW.ERROR),
        //       type: 'danger',
        //     }),
        //   );
      });
    dispatch(setLoadingWallet('withdraw'));
  },
);

export const buyCrypto = createAsyncThunk(
  'wallet/buyCrypto',
  async (actionParams: TActionParams<TBuyCrypto>, { dispatch, getState }) => {
    dispatch(setLoadingWallet('buyCrypto'));
    const { t, data } = actionParams;
    await walletApi
      .payment(data)
      .then(async (res) => {
        const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY ?? '');
        stripe &&
          stripe.redirectToCheckout({
            sessionId: res?.data?.toString(),
          });
      })
      .catch((err: AxiosError<TErrorApiResponse>) => {
        t &&
          dispatch(
            addToast({
              message:
                err.response?.data?.title ||
                err.response?.data?.toString() ||
                err.message ||
                t(translations.NOTIFICATION.WALLET.BUY_CRYPTO.ERROR),
              type: 'danger',
            }),
          );
      });
    dispatch(setLoadingWallet('buyCrypto'));
  },
);

const slice = createSlice({
  name: 'wallet',
  initialState,
  extraReducers: (builder) => {
    // getTransactionHistories
    builder.addCase(
      getTransactionHistories.fulfilled,
      (
        state: WalletState,
        action: PayloadAction<AxiosResponse<TTransactionHistory[], TTransactionHistory>>,
      ) => {
        const totalCount = action?.payload?.headers['x-total-count'];
        state.transactionHistories = action.payload.data;
        state.paginationTransactionHistories = {
          page: state.paramsTransactionHistories.page || DEFAULT_PAGE,
          size: state.paramsTransactionHistories.size || DEFAULT_SIZE,
          totalResults: Number(totalCount) || DEFAULT_TOTAL_RESULTS,
        };
      },
    );

    // getPaymentHistories
    builder.addCase(
      getPaymentHistories.fulfilled,
      (
        state: WalletState,
        action: PayloadAction<AxiosResponse<TPaymentHistory[], TPaymentHistory>>,
      ) => {
        const totalCount = action?.payload?.headers['x-total-count'];
        state.paymentHistories = action.payload.data;
        state.paginationPaymentHistories = {
          page: state.paramsPaymentHistories.page || DEFAULT_PAGE,
          size: state.paramsPaymentHistories.size || DEFAULT_SIZE,
          totalResults: Number(totalCount) || DEFAULT_TOTAL_RESULTS,
        };
      },
    );
  },
  reducers: {
    setParams(state, action: PayloadAction<TOptionsQuery<TTransactionHistory>>) {
      state.paramsTransactionHistories = action.payload;
    },
    setLoadingWallet(state, action: PayloadAction<TLoadingWallet>) {
      if (state.loadingWallet.includes(action.payload)) {
        state.loadingWallet = state.loadingWallet.filter((l) => l !== action.payload);
      } else {
        state.loadingWallet = [...state.loadingWallet, action.payload];
      }
    },
    setUserWallets(state, action: PayloadAction<TUserWallet[]>) {
      state.userWallets = action.payload;
    },
  },
});

export const { actions, reducer: walletReducer } = slice;
export const { setUserWallets, setParams, setLoadingWallet } = actions;
export default walletReducer;
