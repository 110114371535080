import { axiosClient } from 'axiosApi/axiosClient';
import queryString from 'query-string';
import { TOptionsQuery } from 'types/common';
import { TChangeFcmToken, TNotification } from 'types/noti';

const notiApi = {
  changeFcmToken: (data: TChangeFcmToken) => {
    const url = 'user-informations/fcm-token';
    return axiosClient.put(url, data);
  },
  getNotifications: (params: TOptionsQuery<TNotification>) => {
    const url = 'notifications/me';
    const query = queryString.stringify(params);
    return axiosClient.get(`${url}?${query}`);
  },
  readNotification: (id: number) => {
    const url = `notifications/readOne/${id}`;
    return axiosClient.get(url);
  },
  readAllNotifications: () => {
    const url = 'notifications/readAll';
    return axiosClient.get(url);
  },
  deleteAllNotifications: () => {
    const url = 'notifications/deleteAll';
    return axiosClient.delete(url);
  },
};

export default notiApi;
