import { getThemeObjectFromLS } from 'helpers/localStorage';
import { TThemeObject } from 'types/theme';

const themeLocal = getThemeObjectFromLS();
export type MainState = {
  modalopen: boolean;
  theme: TThemeObject;
};

const initialState: MainState = {
  modalopen: false,
  theme: themeLocal,
};

const Mainreducer = (state = initialState, action: any) => {
  switch (action.type) {
    case 'MODAL_OPEN': {
      return {
        ...state,
        modalopen: !!action.payload,
      };
    }
    case 'THEME': {
      return {
        ...state,
        theme: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
export default Mainreducer;
