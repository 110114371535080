import React from 'react';

type Props = {};

const WalletIcon = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="30px"
      height="30px"
      viewBox="0 0 64 64"
    >
      <path
        className="st1"
        d="M15,24c-3.86,0-7,2.691-7,6v20c0,3.309,3.14,6,7,6h41V32H15c-1.598,0-3-0.935-3-2s1.402-2,3-2h5.25 c0,0,1-5,5.75-5s6,5,6,5h22v-4H15z"
        style={{ fill: 'var(--primary-color)' }}
        data-st="fill:var(--chart-color4)"
      ></path>
      <path
        className="st0"
        d="M42,4c-4.418,0-8,3.582-8,8s3.582,8,8,8c4.417,0,8-3.582,8-8S46.417,4,42,4z M42,16c-2.208,0-4-1.792-4-4 s1.792-4,4-4s4,1.792,4,4S44.208,16,42,16z"
      ></path>
      <path
        className="st0"
        d="M26,20c-4.418,0-8,3.582-8,8h4c0-2.208,1.792-4,4-4s4,1.792,4,4h4C34,23.582,30.418,20,26,20z"
      ></path>
    </svg>
  );
};

export default WalletIcon;
