import NewModal from 'Component/Comman/NewModal';
import WalletIcon from 'Component/Icons/WalletIcon/WalletIcon';
import LogoHeader from 'Component/Logo/LogoHeader';
import { onChangeTheme, onModalOpen } from 'Redux/Actions/Actions';
import { addToast } from 'Redux/Reducers/toastSlice';
import { logOut } from 'Screen/Auth/slice/authSlice';
import notiApi from 'axiosApi/api/notiApi';
import { isMobile } from 'helpers/common';
import { getFcmToken } from 'helpers/localStorage';
import { useAppDispatch } from 'index';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Epath } from 'routes/routesConfig';
import { RootState } from 'types/RootState';
import { ContainerClass, TErrorApiResponse } from 'types/common';
import NotificationsDropdown from './components/NotificationsDropdown';
import UserDropdown from './components/UserDropdown';
import SettingIcon from 'Component/Icons/SettingIcon/SettingIcon';
import LanguagesDropdown from './components/LanguagesDropdown';
import { useEffect } from 'react';
import { getNotificationsUnread } from 'Screen/Notifications/slice/notificationSlice';
import { AxiosError } from 'axios';

type Props = {
  container?: ContainerClass;
};

function Header({ container = 'container-xxl' }: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { currentUser, authenticated } = useSelector((state: RootState) => state.auth);
  const { modalopen, theme } = useSelector((state: RootState) => state.main);

  useEffect(() => {
    authenticated && dispatch(getNotificationsUnread());
  }, [authenticated]);

  const handleLogout = async () => {
    const fcmToken = getFcmToken();
    await notiApi
      .changeFcmToken({ delete: true, fcmToken })
      .then(() => {
        dispatch(logOut());
        dispatch(
          addToast({ message: t(translations.NOTIFICATION.AUTH.LOGOUT_SUCCESS), type: 'success' }),
        );
      })
      .catch((err: AxiosError<TErrorApiResponse>) => {
        t &&
          dispatch(
            addToast({
              message:
                err.response?.data?.title ||
                err.response?.data?.toString() ||
                err.message ||
                t(translations.NOTIFICATION.AUTH.LOGOUT_FAILED),
              type: 'danger',
            }),
          );
      });
  };

  return (
    <>
      <div className="header">
        <nav className="navbar py-4">
          <div className={container}>
            <div className="order-0 col-lg-4 col-md-3 col-sm-2 col-2 mb-md-0 d-flex align-items-center">
              <LogoHeader height={isMobile ? 30 : 50} />
            </div>
            <div className="h-right d-flex align-items-center mr-5 mr-lg-0 order-1">
              {authenticated && <NotificationsDropdown />}
              {/* <LanguagesDropdown currentUser={currentUser} authenticated={authenticated} /> */}
              {authenticated && (
                <div className="d-flex mx-2 mx-lg-3">
                  <Link
                    className="nav-link text-primary collapsed"
                    to={Epath.walletPage}
                    title="Wallet"
                  >
                    <WalletIcon />
                  </Link>
                </div>
              )}
              {/* <UserDropdown
                handleLogout={handleLogout}
                currentUser={currentUser}
                authenticated={authenticated}
              /> */}
              <div className="setting cursor-pointer">
                <a
                  data-bs-toggle="modal"
                  data-bs-target="#Settingmodal"
                  onClick={() => {
                    onModalOpen(true)(dispatch);
                  }}
                >
                  <SettingIcon />
                </a>
              </div>
            </div>
            {authenticated && (
              <button
                className="navbar-toggler p-0 border-0 menu-toggle order-3"
                type="button"
                onClick={() => {
                  let sidebar = document.getElementById('mainsidemenu');
                  if (sidebar) {
                    if (sidebar.classList.contains('open')) {
                      sidebar.classList.remove('open');
                    } else {
                      sidebar.classList.add('open');
                    }
                  }
                }}
              >
                <span className="fa fa-bars"></span>
              </button>
            )}
          </div>
        </nav>
      </div>
      <NewModal
        show={modalopen}
        theme={theme}
        onHide={() => {
          onModalOpen(false)(dispatch);
        }}
        onChangeDarkMode={() => {
          onChangeTheme({
            ...theme,
            data: theme.data === 'dark' ? 'light' : 'dark',
          })(dispatch);
        }}
        onChangeHighContrast={() => {
          onChangeTheme({
            ...theme,
            data: theme.data === 'high-contrast' ? 'light' : 'high-contrast',
          })(dispatch);
        }}
      />
    </>
  );
}

export default Header;
