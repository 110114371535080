import { saveThemeObjectLS } from 'helpers/localStorage';
import { TThemeObject } from 'types/theme';

export const onModalOpen = (val: boolean) => (dispatch: any) => {
  dispatch({
    type: 'MODAL_OPEN',
    payload: val,
  });
};
export const onChangeTheme = (theme: TThemeObject) => (dispatch: any) => {
  saveThemeObjectLS(theme);
  dispatch({
    type: 'THEME',
    payload: theme,
  });
};
