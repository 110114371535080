import {
  TForgotPassRequest,
  TRegisterRequest,
  TResetPassword,
  TSendOTP,
  TSignInRequest,
  TVerifyOTP,
} from 'Screen/Auth/slice/authTypes';
import { axiosClient } from '../axiosClient';

const authApi = {
  login: (data: TSignInRequest) => {
    const url = 'authenticate';
    return axiosClient.post(url, data);
  },
  logout: (refreshToken: string) => {
    const url = 'logout';
    return axiosClient.post(url, { refreshToken });
  },
  forgotPassword: (data: TForgotPassRequest) => {
    const url = 'account/reset-password/init';
    return axiosClient.post(url, data);
  },
  resetPassword: (data: TResetPassword) => {
    const url = 'account/reset-password/finish';
    return axiosClient.post(url, data);
  },
  register: (data: TRegisterRequest) => {
    const url = 'register';
    return axiosClient.post(url, data);
  },
  sendOTP: (data: TSendOTP) => {
    const url = 'send-otp';
    return axiosClient.post(url, data);
  },
  verifyOTP: (data: TVerifyOTP) => {
    const url = 'verify-otp';
    return axiosClient.post(url, data);
  },
  // verifyEmail: (token: string) => {
  //   const url = 'verify-email';
  //   const query = queryString.stringify({ token });
  //   return axiosClient.post(`${url}?${query}`);
  // },
  // changeEmail: (data: TChangeEmail) => {
  //   const url = 'change-email';
  //   return axiosClient.post(url, data);
  // },
  // changePassword: (data: TChangePassword) => {
  //   const url = 'change-password';
  //   return axiosClient.post(url, data);
  // },
};

export default authApi;
