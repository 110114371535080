import Sidebar from 'Component/Comman/Sidebar';
import { useAppDispatch } from 'index';
import { ReactNode, useRef } from 'react';
import Header from '../Component/Header/Header';

export type PropsLayout = {
  children: ReactNode;
};

function MainIndex({ children }: PropsLayout) {
  const dispatch = useAppDispatch();
  const refMain = useRef<HTMLDivElement>(null);
  const refSidebar = useRef<HTMLDivElement>(null);

  return (
    <>
      <Sidebar refSidebar={refSidebar} refMain={refMain} />
      <div className="main px-lg-4 px-md-4">
        <Header />
        <div className="body d-flex py-3" ref={refMain}>
          {children}
        </div>
      </div>
    </>
  );
}

export default MainIndex;
