import MyToast from 'Component/Toast/MyToast';
import { DEFAULT_TIME_UPDATE_BALANCES, isEmptyObject } from 'helpers/common';
import { socketKeys } from 'helpers/const/socket';
import {
  getLanguage,
  getTokenFromLocalStorage,
  saveLanguage,
  saveResLoginObjectLS,
} from 'helpers/localStorage';
import { useAppDispatch } from 'index';
import { DEFAULT_LANGUAGE, languages } from 'locales/i18n';
import moment from 'moment';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import RenderRoutes, { routes } from 'routes/routes';
import { getCurrentUser, setAuth } from 'Screen/Auth/slice/authSlice';
import { getUpdateBalances, getUserWallets } from 'Screen/WalletPage/slice/walletSlice';
import socketIO from 'socket/socket';
import { RootState } from 'types/RootState';

// Import moment locale
import 'moment/locale/es';
import 'moment/locale/ja';
import { checkFcmToken } from 'helpers/handleFcmToken';
import { messageFirebase } from 'helpers/firebase/lib';
import { addToast } from 'Redux/Reducers/toastSlice';
import { useLocation } from 'react-router-dom';
import { parseQueryParams } from 'helpers/functions';
import {
  getNotifications,
  getNotificationsUnread,
} from 'Screen/Notifications/slice/notificationSlice';

// Set language + moment
const language = getLanguage();
saveLanguage(language);
moment.locale(language);

function App() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { i18n } = useTranslation();
  const { authenticated, currentUser, responseLogin } = useSelector(
    (state: RootState) => state.auth,
  );
  const { userWallets } = useSelector((state: RootState) => state.wallet);
  const { theme } = useSelector((state: RootState) => state.main);

  // const localUser = getUserFromLocalStorage();
  // const localRefreshToken = getRefreshTokenFromLocalStorage();
  const checkAuthLocal = !!getTokenFromLocalStorage();

  const isAuthenticated = !!(authenticated && currentUser && Object.keys(currentUser).length);

  // useEffect(() => {
  //   // Handle socket
  //   if (checkAuthLocal) {
  //     socketIO.connect();
  //   } else {
  //     socketIO.disconnect();
  //   }
  //   socketIO.on(socketKeys.CONNECT_ERROR, () => {
  //     socketIO.disconnect();
  //   });
  //   return () => {
  //     socketIO.disconnect();
  //   };
  // }, []);

  useEffect(() => {
    const themeDom = document.getElementById('fruits-layout');
    if (!!themeDom) themeDom.className = `theme-${theme.color}`;
  }, [theme.color]);

  useEffect(() => {
    window.document.children[0].setAttribute('data-theme', theme.data);
  }, [theme.data]);

  useEffect(() => {
    if (theme.rtl) {
      document.body.classList.add('rtl_mode');
    } else {
      document.body.classList.remove('rtl_mode');
    }
  }, [theme.rtl]);

  useEffect(() => {
    if (checkAuthLocal && !isAuthenticated) {
      dispatch(setAuth());
    }
  }, []);

  useEffect(() => {
    if (authenticated && checkAuthLocal) {
      // Handle firebase/messaging
      checkFcmToken();

      dispatch(getCurrentUser());
      dispatch(getUserWallets());
    }
  }, [authenticated, checkAuthLocal]);

  useEffect(() => {
    if (currentUser && currentUser.langKey && currentUser.langKey !== language) {
      i18n.changeLanguage(
        languages.includes(currentUser.langKey) ? currentUser.langKey : DEFAULT_LANGUAGE,
      );
    }
  }, [currentUser]);

  useEffect(() => {
    if (responseLogin && !isEmptyObject(responseLogin)) {
      saveResLoginObjectLS(responseLogin);
    }
  }, [responseLogin]);

  // UpdateBalances BTC
  useEffect(() => {
    const btcWallet = userWallets.find((w) => w.cryptoCurrency?.symbol === 'BTC');
    if (authenticated && btcWallet && btcWallet.depositAddress) {
      let lastTimeUpdateBalances = localStorage.getItem('lastTimeUpdateBalances') || '0';

      const timeSinceLastShown = Date.now() - parseInt(lastTimeUpdateBalances);

      const func = () => {
        dispatch(getUpdateBalances(btcWallet.depositAddress ?? ''));
        localStorage.setItem('lastTimeUpdateBalances', Date.now().toString());
      };

      if (timeSinceLastShown >= DEFAULT_TIME_UPDATE_BALANCES) {
        func();
      }

      const intervalId = setInterval(() => {
        const timeSinceLastShown = Date.now() - parseInt(lastTimeUpdateBalances);

        if (timeSinceLastShown >= DEFAULT_TIME_UPDATE_BALANCES) {
          func();
        }
      }, DEFAULT_TIME_UPDATE_BALANCES);

      return () => clearInterval(intervalId);
    }
  }, [authenticated, userWallets]);

  if (messageFirebase) {
    messageFirebase.onMessage((payload) => {
      if (!payload?.notification) {
        return;
      }
      const { notification } = payload;
      const { pathname, search } = location;
      if (pathname === '/notifications') {
        dispatch(getNotifications(parseQueryParams(search)));
      } else {
        dispatch(getNotificationsUnread());
      }
      dispatch(
        addToast({
          message: notification.title
            ? `${notification.title}: ${notification.body}`
            : notification.body,
          type: 'primary',
        }),
      );
    });
  }

  return (
    <>
      <div id="fruits-layout" className="theme-orange">
        <RenderRoutes routes={routes} isAuthenticated={isAuthenticated || checkAuthLocal} />
      </div>
      <MyToast />
    </>
  );
}

export default App;
