import { getCurrentUser, logOut } from 'Screen/Auth/slice/authSlice';
import { getTokenFromLocalStorage } from 'helpers/localStorage';
import SockJS from 'sockjs-client';
import Stomp from 'webstomp-client';
import { subscribeOrderBook, unsubscribeOrderBook } from './subscribes/subscribeOrderBook';
import { subscribeCoinTrend, unsubscribeCoinTrend } from './subscribes/subcribeCoinTrend';
import { subscribeCoinPair, unsubscribeCoinPair } from './subscribes/subcribeCoinPair';

let stompClient: any = null;
let connection: Promise<any>;
let connectedPromise: any = null;
let alreadyConnectedOnce = false;

const createConnection = (): Promise<any> => new Promise((resolve) => (connectedPromise = resolve));

const connect = () => {
  if (connectedPromise !== null || alreadyConnectedOnce) {
    return;
  }
  connection = createConnection();
  const headers = {};

  let url = process.env.REACT_APP_END_POINT_SOCKET + '/websocket/tracker';
  const authToken = getTokenFromLocalStorage() ?? '';
  if (authToken) {
    url += '?access_token=' + authToken;
  }
  const socket = new SockJS(url);
  stompClient = Stomp.over(socket, { protocols: ['v12.stomp'] });
  stompClient.debug = function () {};

  stompClient.connect(headers, () => {
    connectedPromise('success');
    connectedPromise = null;
    alreadyConnectedOnce = true;
  });
};

const disconnect = () => {
  if (stompClient !== null) {
    if (stompClient.connected) {
      stompClient.disconnect();
    }
    stompClient = null;
  }
  alreadyConnectedOnce = false;
};

const subscribes = (dispatch: any, connection: any, stompClient: any) => {
  subscribeOrderBook(dispatch, connection, stompClient);
  // subscribeCoinTrend(dispatch, connection, stompClient);
  // subscribeCoinPair(dispatch, connection, stompClient);
};

const unsubscribes = () => {
  unsubscribeOrderBook();
  unsubscribeCoinTrend();
  unsubscribeCoinPair();
};

const websocketMiddleware = (store: any) => (next: any) => (action: any) => {
  const { dispatch } = store;
  if (getCurrentUser.fulfilled.match(action)) {
    connect();
    if (!alreadyConnectedOnce) {
      subscribes(dispatch, connection, stompClient);
    }
  } else if (getCurrentUser.rejected.match(action) || action.type === logOut().type) {
    unsubscribes();
    disconnect();
  }
  return next(action);
};
export default websocketMiddleware;
