import React, { Dispatch } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { configureAppStore } from 'Redux/configureStore';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import '@klinecharts/pro/dist/klinecharts-pro.css';
import './assets/css/icon.css';
import './assets/scss/main.scss';

// Initialize languages
import './locales/i18n';
import { useDispatch } from 'react-redux';
import { AsyncThunkAction } from '@reduxjs/toolkit';

const rootEl = document.getElementById('root');

const store = configureAppStore();
export type AppDispatch = typeof store.dispatch & Dispatch<AsyncThunkAction<any, any, any>>;
export const useAppDispatch: () => AppDispatch = useDispatch;

if (rootEl) {
  const root = ReactDOM.createRoot(rootEl);
  root.render(
    // By using React.StrictMode to wrap the App component in index.tsx,
    // React will perform checks on all lifecycle methods of the component to ensure stability and avoid unexpected behavior.
    // So, when in development mode and warnings or errors occur, React will re-render the component again to check if any new warnings or errors appear.
    // This may explain why your App component is being re-rendered twice, once when mounting and once when unmounting and re-mounting.
    // <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>,
    // </React.StrictMode>,
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
