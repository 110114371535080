import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';
import notiApi from 'axiosApi/api/notiApi';
import {
  DEFAULT_PAGE,
  DEFAULT_SIZE,
  DEFAULT_TOTAL_RESULTS,
  defaultPagination,
} from 'helpers/common';
import { IndexedObject, TActionParams, TErrorApiResponse, TOptionsQuery } from 'types/common';
import { TNotification } from 'types/noti';
import { RootState } from 'types/RootState';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { NotificationState } from './notificationTypes';
import { addToast } from 'Redux/Reducers/toastSlice';
import { translations } from 'locales/translations';

export const DEFAULT_SORT_NOTIFICATIONS = 'createdDate,asc';
export const DEFAULT_SIZE_NOTIFICATIONS_UNREAD = 6;

const initialState: NotificationState = {
  params: {},
  pagination: defaultPagination,
  loadingNotification: false,
  notifications: [],
  notificationsUnread: [],
  unreadNotiNumber: 0,
};

export const getNotifications = createAsyncThunk(
  'notification/getNotifications',
  async (params: TOptionsQuery<TNotification>, { dispatch, getState }) => {
    dispatch(setParams(params));
    const state = (getState() as RootState).notification;
    const {
      sort = state.params.sort || DEFAULT_SORT_NOTIFICATIONS,
      size = state.params.size || DEFAULT_SIZE,
      page = state.params.page || DEFAULT_PAGE,
    } = params ? params : {};
    let newParams: TOptionsQuery<TNotification> = Object.create(null);
    newParams.sort = sort;
    newParams.size = size;
    newParams.page = page - 1;
    const res = await notiApi.getNotifications({ ...params, ...newParams });
    return res;
  },
);

export const getNotificationsUnread = createAsyncThunk(
  'notification/getNotificationsUnread',
  async () => {
    const params = {
      sort: DEFAULT_SORT_NOTIFICATIONS,
      size: DEFAULT_SIZE_NOTIFICATIONS_UNREAD,
      page: DEFAULT_PAGE - 1,
    };
    const res = await notiApi.getNotifications({ ...params, read: false });
    return res;
  },
);

export const readNotificationAction = createAsyncThunk(
  'order/readNotificationAction',
  async (id: number, { dispatch }) => {
    dispatch(setLoadingNotification(true));
    await notiApi
      .readNotification(id)
      .then(() => {
        dispatch(readNotification(id));
      })
      .catch(() => {});
    dispatch(setLoadingNotification(false));
  },
);

export const readAllNotificationsAction = createAsyncThunk(
  'order/readAllNotificationsAction',
  async (actionParams: TActionParams<IndexedObject>, { dispatch }) => {
    dispatch(setLoadingNotification(true));
    const { t } = actionParams;
    await notiApi
      .readAllNotifications()
      .then(() => {
        dispatch(readAllNotifications());
      })
      .catch((err: AxiosError<TErrorApiResponse>) => {
        t &&
          dispatch(
            addToast({
              message:
                err.response?.data?.title ||
                err.response?.data?.toString() ||
                err.message ||
                t(translations.NOTIFICATION.WALLET.TRANSFER.ERROR),
              type: 'danger',
            }),
          );
      });
    dispatch(setLoadingNotification(false));
  },
);

export const deleteAllNotificationsAction = createAsyncThunk(
  'order/deleteAllNotificationsAction',
  async (actionParams: TActionParams<IndexedObject>, { dispatch }) => {
    dispatch(setLoadingNotification(true));
    const { t } = actionParams;
    await notiApi
      .deleteAllNotifications()
      .then(() => {
        dispatch(deleteAllNotifications());
      })
      .catch((err: AxiosError<TErrorApiResponse>) => {
        t &&
          dispatch(
            addToast({
              message:
                err.response?.data?.title ||
                err.response?.data?.toString() ||
                err.message ||
                t(translations.NOTIFICATION.WALLET.TRANSFER.ERROR),
              type: 'danger',
            }),
          );
      });
    dispatch(setLoadingNotification(false));
  },
);

const slice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setLoadingNotification(state, action: PayloadAction<boolean>) {
      state.loadingNotification = action.payload;
    },
    setParams(state, action: PayloadAction<TOptionsQuery<TNotification>>) {
      state.params = action.payload;
    },
    readNotification(state, action: PayloadAction<number>) {
      const { notificationsUnread, notifications } = state;
      if (notificationsUnread.find((n) => n.id === action.payload)) {
        state.notificationsUnread = notificationsUnread.filter((n) => n.id !== action.payload);
        state.unreadNotiNumber = state.unreadNotiNumber - 1;
      }
      state.notifications = notifications.map((n) =>
        n.id === action.payload
          ? {
              ...n,
              read: true,
            }
          : n,
      );
    },
    readAllNotifications(state) {
      state.notificationsUnread = [];
      state.unreadNotiNumber = 0;
      state.notifications = state.notifications.map((n) => ({
        ...n,
        read: true,
      }));
    },
    deleteAllNotifications(state) {
      state.notificationsUnread = [];
      state.unreadNotiNumber = 0;
      state.notifications = [];
      state.params = {};
      state.pagination = defaultPagination;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNotifications.pending, (state: NotificationState) => {
      state.loadingNotification = true;
    });
    builder.addCase(getNotifications.rejected, (state: NotificationState) => {
      state.loadingNotification = false;
    });
    builder.addCase(
      getNotifications.fulfilled,
      (state: NotificationState, action: PayloadAction<AxiosResponse<TNotification[], any>>) => {
        const totalCount = action?.payload?.headers['x-total-count'];
        state.loadingNotification = false;
        state.notifications = action.payload.data;
        state.pagination = {
          page: state.params.page || DEFAULT_PAGE,
          size: state.params.size || DEFAULT_SIZE,
          totalResults: Number(totalCount) || DEFAULT_TOTAL_RESULTS,
        };
      },
    );

    builder.addCase(
      getNotificationsUnread.fulfilled,
      (state: NotificationState, action: PayloadAction<AxiosResponse<TNotification[], any>>) => {
        const totalCount = action?.payload?.headers['x-total-count'];
        state.notificationsUnread = action.payload.data;
        state.unreadNotiNumber = Number(totalCount) ?? 0;
      },
    );
  },
});

export const { actions, reducer: notificationReducer } = slice;
export const {
  setLoadingNotification,
  setParams,
  readNotification,
  readAllNotifications,
  deleteAllNotifications,
} = actions;
export default notificationReducer;
