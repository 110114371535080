import { axiosClient } from 'axiosApi/axiosClient';
import { TCoinPair } from 'types/coin';
import { TOptionsQuery } from 'types/common';
import queryString from 'query-string';
import axios from 'axios';
import { TCryptoCurrency } from 'types/crypto';

const marketApi = {
  getCoinPairs: (params: TOptionsQuery<TCoinPair>) => {
    const url = 'coin-pairs';
    const query = queryString.stringify(params);
    return axiosClient.get(`${url}?${query}`);
  },
  getCryptoCurrencies: (params: TOptionsQuery<TCryptoCurrency>) => {
    const url = 'crypto-currencies';
    const query = queryString.stringify(params);
    return axiosClient.get(`${url}?${query}`);
  },
  getCoinPair: (id: string) => {
    const url = `coin-pairs/${id}`;
    return axiosClient.get(url);
  },
  getCoinPairTrend: () => {
    const url = 'coin-pairs/trend';
    return axiosClient.get(url);
  },
  getCoinTrend: () => {
    const url = 'crypto-currencies/trend';
    return axiosClient.get(url);
  },

  getMarketCap: () => {
    const url = 'https://pro-api.coinmarketcap.com/v1/cryptocurrency/trending/latest';
    return axios.get(url, {
      headers: {
        'X-CMC_PRO_API_KEY': 'd23fab9f-1a90-4bd0-86b0-27da5e066b1e',
      },
    });
  },
};

export default marketApi;
