import React from 'react';
import IconNoImage from 'assets/images/icon-no-image.jpg';

type Props = {
  src?: string;
  alt?: string;
  className?: string;
};

const ImageCustom = ({ src, alt = '', className, ...props }: Props) => {
  return (
    <img
      className={className}
      src={src || IconNoImage}
      {...props}
      alt={alt}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null;
        currentTarget.src = IconNoImage;
      }}
    />
  );
};

export default ImageCustom;
