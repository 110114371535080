import { ToastContainer } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from 'types/RootState';
import './MyToast.scss';
import ToastItem from './ToastItem';

const MyToast = () => {
  const { toasts } = useSelector((state: RootState) => state.toast);

  if (!toasts.length) return null;

  return (
    <ToastContainer position="top-end" containerPosition="fixed">
      {toasts.map((t) => (
        <ToastItem toast={t} key={t.id} />
      ))}
    </ToastContainer>
  );
};
export default MyToast;
