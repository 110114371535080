import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';
import orderApi from 'axiosApi/api/orderApi';
import {
  defaultPagination,
  DEFAULT_PAGE,
  DEFAULT_SIZE,
  DEFAULT_TOTAL_RESULTS,
} from 'helpers/common';
import { translations } from 'locales/translations';
import { addToast } from 'Redux/Reducers/toastSlice';
import { TActionParams, TErrorApiResponse, TOptionsQuery } from 'types/common';
import { TOrder, TOrderBook } from 'types/order';
import { RootState } from 'types/RootState';
import { createSlice } from 'utils/@reduxjs/toolkit';
import {
  OrderState,
  TCreateOrder,
  TDeleteOrder,
  TGetOrdersBook,
  TLoadingOrders,
} from './orderTypes';

export const DEFAULT_SORT_ORDER = 'createdDate,desc';

const initialState: OrderState = {
  loadingOrders: [],
  // Orders
  paramsOrders: {},
  paginationOrders: defaultPagination,
  orders: [],

  // Open orders
  paramsOpenOrders: {},
  paginationOpenOrders: defaultPagination,
  openOrders: [],

  // Order book
  orderBook: [],
};

export const getOrders = createAsyncThunk(
  'order/getOrders',
  async (params: TOptionsQuery<TOrder>, { dispatch, getState }) => {
    dispatch(setLoadingOrders('orders'));
    dispatch(setParamsOrders(params));
    const state = (getState() as RootState).order;
    const {
      sort = state.paramsOrders.sort || DEFAULT_SORT_ORDER,
      size = state.paramsOrders.size || DEFAULT_SIZE,
      page = state.paramsOrders.page || DEFAULT_PAGE,
    } = params ? params : {};
    let newParams: TOptionsQuery<TOrder> = Object.create(null);
    newParams.sort = sort;
    newParams.size = size;
    newParams.page = page - 1;
    const res = await orderApi.getOrders({ ...params, ...newParams });
    dispatch(setLoadingOrders('orders'));
    return res;
  },
);

export const getOpenOrders = createAsyncThunk(
  'order/getOpenOrders',
  async (params: TOptionsQuery<TOrder>, { dispatch, getState }) => {
    const paramsOpenOrders: TOptionsQuery<TOrder> = { orderStatus: 'TRADING' };
    dispatch(setLoadingOrders('orders'));
    dispatch(setParamsOpenOrders({ ...params, ...paramsOpenOrders }));
    const state = (getState() as RootState).order;
    const {
      sort = state.paramsOpenOrders.sort || DEFAULT_SORT_ORDER,
      size = state.paramsOpenOrders.size || DEFAULT_SIZE,
      page = state.paramsOpenOrders.page || DEFAULT_PAGE,
    } = params ? params : {};
    let newParams: TOptionsQuery<TOrder> = Object.create(null);
    newParams.sort = sort;
    newParams.size = size;
    newParams.page = page - 1;
    const res = await orderApi.getOrders({ ...params, ...newParams, ...paramsOpenOrders });
    dispatch(setLoadingOrders('orders'));
    return res;
  },
);

// Lock to use socket
// export const getOrderBook = createAsyncThunk(
//   'order/getOrderBook',
//   async (params: TGetOrdersBook) => {
//     const res = await orderApi.getOrderBook(params);
//     return res.data;
//   },
// );

export const createOrder = createAsyncThunk(
  'order/createOrder',
  async (actionParams: TActionParams<TCreateOrder>, { dispatch, getState }) => {
    dispatch(setLoadingOrders('spot'));
    const { t, data, funct } = actionParams;
    const { paramsOrders, paginationOpenOrders } = (getState() as RootState).order;
    await orderApi
      .createOrder(data)
      .then(() => {
        dispatch(getOpenOrders(paramsOrders));
        dispatch(getOrders(paginationOpenOrders));
        funct && funct();
        t &&
          dispatch(
            addToast({
              message: t(translations.NOTIFICATION.SAVE_SUCCESS_DEFAULT),
              type: 'success',
            }),
          );
      })
      .catch((err: AxiosError<TErrorApiResponse>) => {
        t &&
          dispatch(
            addToast({
              message:
                err.response?.data?.title ||
                err.response?.data?.toString() ||
                err.message ||
                t(translations.NOTIFICATION.ERROR_DEFAULT),
              type: 'danger',
            }),
          );
      });
    dispatch(setLoadingOrders('spot'));
  },
);

export const deleteOrder = createAsyncThunk(
  'order/deleteOrder',
  async (actionParams: TActionParams<TDeleteOrder>, { dispatch, getState }) => {
    dispatch(setLoadingOrders('orders'));
    const { t, data } = actionParams;
    const { paramsOpenOrders, paramsOrders } = (getState() as RootState).order;
    await orderApi
      .deleteOrder(data.id)
      .then(() => {
        dispatch(getOpenOrders(paramsOpenOrders));
        dispatch(getOrders(paramsOrders));
        t &&
          dispatch(
            addToast({
              message: t(translations.NOTIFICATION.SAVE_SUCCESS_DEFAULT),
              type: 'success',
            }),
          );
      })
      .catch((err: AxiosError<TErrorApiResponse>) => {
        t &&
          dispatch(
            addToast({
              message:
                err.response?.data?.title ||
                err.response?.data?.toString() ||
                err.message ||
                t(translations.NOTIFICATION.ERROR_DEFAULT),
              type: 'danger',
            }),
          );
      });
    dispatch(setLoadingOrders('orders'));
  },
);

const slice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setLoadingOrders(state, action: PayloadAction<TLoadingOrders>) {
      if (state.loadingOrders.includes(action.payload)) {
        state.loadingOrders = state.loadingOrders.filter((l) => l !== action.payload);
      } else {
        state.loadingOrders = [...state.loadingOrders, action.payload];
      }
    },
    setParamsOrders(state, action: PayloadAction<TOptionsQuery<TOrder>>) {
      state.paramsOrders = action.payload;
    },
    setParamsOpenOrders(state, action: PayloadAction<TOptionsQuery<TOrder>>) {
      state.paramsOpenOrders = action.payload;
    },

    // Socket
    setOrderBook(state, action: PayloadAction<TOrderBook[]>) {
      state.orderBook = action.payload;
    },
  },
  extraReducers: (builder) => {
    // getOrders
    builder.addCase(
      getOrders.fulfilled,
      (state: OrderState, action: PayloadAction<AxiosResponse<TOrder[], any>>) => {
        const totalCount = action?.payload?.headers['x-total-count'];
        state.orders = action.payload.data;
        state.paginationOrders = {
          page: state.paramsOrders.page || DEFAULT_PAGE,
          size: state.paramsOrders.size || DEFAULT_SIZE,
          totalResults: Number(totalCount) || DEFAULT_TOTAL_RESULTS,
        };
      },
    );

    // getOpenOrders
    builder.addCase(
      getOpenOrders.fulfilled,
      (state: OrderState, action: PayloadAction<AxiosResponse<TOrder[], any>>) => {
        const totalCount = action?.payload?.headers['x-total-count'];
        state.openOrders = action.payload.data;
        state.paginationOpenOrders = {
          page: state.paramsOpenOrders.page || DEFAULT_PAGE,
          size: state.paramsOpenOrders.size || DEFAULT_SIZE,
          totalResults: Number(totalCount) || DEFAULT_TOTAL_RESULTS,
        };
      },
    );

    // getOrderBook
    // builder.addCase(
    //   getOrderBook.fulfilled,
    //   (state: OrderState, action: PayloadAction<TOrderBook>) => {
    //     state.orderBook = action.payload;
    //   },
    // );
  },
});

export const { actions, reducer: orderReducer } = slice;
export const { setLoadingOrders, setParamsOrders, setParamsOpenOrders, setOrderBook } = actions;
export default orderReducer;
