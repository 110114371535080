import { translations } from 'locales/translations';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { onChangeTheme } from 'Redux/Actions/Actions';
import { TDataTheme, TThemeColor, TThemeObject } from 'types/theme';

type Props = {
  show: boolean;
  theme: TThemeObject;
  onHide: () => void;
  onChangeDarkMode: () => void;
  onChangeHighContrast: () => void;
};

const colors: TThemeColor[] = [
  'indigo',
  'tradewind',
  'monalisa',
  'blue',
  'cyan',
  'green',
  'orange',
  'blush',
  'red',
];

function NewModal({ show, theme, onHide, onChangeDarkMode, onChangeHighContrast }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const Changetheme = (color: TThemeColor) => {
    onChangeTheme({
      ...theme,
      color,
    })(dispatch);
  };

  return (
    <Modal className="modal fade right " id="Settingmodal" show={show} onHide={onHide}>
      <div className="modal-content">
        <Modal.Header className="modal-header" closeButton>
          <h5 className="modal-title">{t(translations.COMPONENTS.THEME.CUSTOM_SETTINGS)}</h5>
        </Modal.Header>
        <Modal.Body className="modal-body custom_setting">
          <div className="setting-theme pb-3">
            <h6 className="card-title mb-2 fs-6 d-flex align-items-center">
              <i className="icofont-color-bucket fs-4 me-2 text-primary"></i>
              {t(translations.COMPONENTS.THEME.COLOR_SETTINGS)}
            </h6>
            <ul className="list-unstyled row row-cols-3 g-2 choose-skin mb-2 mt-2">
              {colors.map((c) => (
                <li key={c} data-theme={c} className={theme.color === c ? 'active' : ''}>
                  <div
                    className={c}
                    onClick={() => {
                      Changetheme(c);
                    }}
                  ></div>
                </li>
              ))}
            </ul>
          </div>
          <div className="setting-mode py-3">
            <h6 className="card-title mb-2 fs-6 d-flex align-items-center">
              <i className="icofont-layout fs-4 me-2 text-primary"></i>
              {t(translations.COMPONENTS.THEME.CONTRAST_LAYOUT)}
            </h6>
            <ul className="list-group list-unstyled mb-0 mt-1">
              <li className="list-group-item d-flex align-items-center py-1 px-2">
                <div className="form-check form-switch theme-switch mb-0">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="theme-switch"
                    checked={theme.data === 'dark'}
                    onChange={onChangeDarkMode}
                  />
                  <label className="form-check-label" htmlFor="theme-switch">
                    {t(translations.COMPONENTS.THEME.DARK_MODE)}
                  </label>
                </div>
              </li>
              <li className="list-group-item d-flex align-items-center py-1 px-2">
                <div className="form-check form-switch theme-high-contrast mb-0">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="theme-high-contrast"
                    checked={theme.data === 'high-contrast'}
                    onChange={onChangeHighContrast}
                  />
                  <label className="form-check-label" htmlFor="theme-high-contrast">
                    {t(translations.COMPONENTS.THEME.HIGH_CONTRAST)}
                  </label>
                </div>
              </li>
            </ul>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
}

export default NewModal;
