import { setOrderBook } from 'Screen/Exchange/slice/orderSlice';
import { TOrderBook } from 'types/order';

let subscriberOrderBook: any = null;

export const subscribeOrderBook = (dispatch: any, connection: any, stompClient: any) => {
  connection?.then(() => {
    subscriberOrderBook = stompClient?.subscribe('/topic/order', (data: any) => {
      const orderBook = JSON.parse(data.body) as TOrderBook[];
      dispatch && dispatch(setOrderBook(orderBook));
    });
  });
};

export const unsubscribeOrderBook = () => {
  if (subscriberOrderBook !== null) {
    subscriberOrderBook?.unsubscribe();
  }
};
