import { DEFAULT_DURATION_TOAST } from 'helpers/common';
import React, { useEffect } from 'react';
import { Toast } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { removeToast, TToast } from 'Redux/Reducers/toastSlice';

type Props = {
  toast: TToast;
};

const ToastItem = ({ toast }: Props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const timeOutToast = setTimeout(() => {
      handleToastClose(toast.id);
      clearTimeout(timeOutToast);
    }, DEFAULT_DURATION_TOAST);

    return () => {
      clearTimeout(timeOutToast);
    };
  }, []);

  const handleToastClose = (id: number) => {
    dispatch(removeToast(id));
  };

  return (
    <Toast
      key={toast.id}
      show={true}
      bg={toast.type}
      className="d-flex align-items-center text-white border-0 toastCustom"
    >
      <Toast.Body>{toast.message}</Toast.Body>
      <button
        type="button"
        className="btn-close btn-close-white ms-auto me-2"
        onClick={() => handleToastClose(toast.id)}
      ></button>
    </Toast>
  );
};

export default ToastItem;
