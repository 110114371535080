import { DEFAULT_LANGUAGE, languages } from 'locales/i18n';
import { TResponseLogin } from 'Screen/Auth/slice/authTypes';
import { IndexedObject } from 'types/common';
import { TThemeObject } from 'types/theme';
import { DEFAULT_THEME_OBJECT } from './const/theme';
import { UserDetail } from 'types/user';

// Token LocalStorage
export const saveToken = (token?: string) => {
  if (token) {
    localStorage.setItem('access_token', token);
  }
};

export const removeToken = () => {
  localStorage.removeItem('access_token');
};

export const getTokenFromLocalStorage = () => {
  const token = localStorage.getItem('access_token');
  return token;
};

// Refresh token LocalStorage
export const saveRefreshToken = (refresh_token?: string) => {
  if (refresh_token) {
    localStorage.setItem('refresh_token', refresh_token);
  }
};

export const removeRefreshToken = () => {
  localStorage.removeItem('refresh_token');
};

export const getRefreshTokenFromLocalStorage = () => {
  const refresh_token = localStorage.getItem('refresh_token');
  return refresh_token ? refresh_token : '';
};

// Save language to localStorage
export const saveLanguage = (language: string) => {
  if (languages.includes(language)) {
    localStorage.setItem('i18nextLng', language);
  } else {
    localStorage.setItem('i18nextLng', DEFAULT_LANGUAGE);
  }
};
export const getLanguage = () => {
  const language = localStorage.getItem('i18nextLng');
  return language && languages.includes(language) ? language : DEFAULT_LANGUAGE;
};

// Current User LocalStorage
export const saveUser = (data_user: UserDetail) => {
  localStorage.setItem('data_user', JSON.stringify(data_user));
};

export const removeUser = () => {
  localStorage.removeItem('data_user');
};

export const getUserFromLocalStorage = (): UserDetail => {
  let data = {};
  if (localStorage.getItem('data_user')) {
    try {
      const userLocal = localStorage.getItem('data_user');
      data = JSON.parse(userLocal ? userLocal : '');
    } catch (e) {
      data = {};
    }
  }
  return data;
};

// Theme
export const saveThemeObjectLS = (object: TThemeObject) => {
  localStorage.setItem('theme', JSON.stringify(object));
};

export const removeThemeObjectLS = () => {
  localStorage.removeItem('theme');
};

export const getThemeObjectFromLS = (): TThemeObject => {
  let data = DEFAULT_THEME_OBJECT;
  if (localStorage.getItem('theme')) {
    try {
      const objectLocal = localStorage.getItem('theme');
      data = JSON.parse(objectLocal ? objectLocal : '');
    } catch (e) {
      data = DEFAULT_THEME_OBJECT;
    }
  }
  return data;
};

// Res login
export const saveResLoginObjectLS = (object: TResponseLogin) => {
  localStorage.setItem('resLogin', JSON.stringify(object));
};

export const removeResLoginObjectLS = () => {
  localStorage.removeItem('resLogin');
};

export const getResLoginObjectFromLS = (): TResponseLogin => {
  let data = {};
  if (localStorage.getItem('resLogin')) {
    try {
      const objectLocal = localStorage.getItem('resLogin');
      data = JSON.parse(objectLocal ? objectLocal : '');
    } catch (e) {
      data = {};
    }
  }
  return data;
};

// Save fcmToken to localStorage
export const saveFcmToken = (fcmToken?: string) => {
  !!fcmToken && localStorage.setItem('fcmToken', fcmToken);
};
export const getFcmToken = () => {
  const fcmToken = localStorage.getItem('fcmToken');
  return fcmToken ? fcmToken : '';
};
export const removeFcmToken = () => {
  localStorage.removeItem('fcmToken');
};
