import moment from 'moment';
import { TTypeToast } from 'Redux/Reducers/toastSlice';
import { IndexedObject, TPagination } from 'types/common';

// Common
export const isMobile = window.screen.width < 768;

export const publicUrl = (uri: string) => {
  return `${process.env.PUBLIC_URL}${uri}`;
};

export const isEmptyObject = (obj: IndexedObject) => {
  if (obj.constructor === Object && Object.keys(obj).length === 0) {
    return true;
  }
  return JSON.stringify(obj) === JSON.stringify({});
};

export const isToday = (date: Date) => moment(date).isSame(moment(), 'day');

export const DEFAULT_SIZE = 10;
export const DEFAULT_PAGE = 1;
export const DEFAULT_TOTAL_RESULTS = 0;

export const defaultPagination: TPagination = {
  size: DEFAULT_SIZE,
  page: DEFAULT_PAGE,
  totalResults: DEFAULT_TOTAL_RESULTS,
};

export const DEFAULT_SIZE_SPINNER_LOADING = 24;

export const DEFAULT_TYPE_TOAST: TTypeToast = 'primary';
export const DEFAULT_DURATION_TOAST = 3000;

export const DEFAULT_TIME_UPDATE_BALANCES = 5 * 60 * 1000;

export const DEFAULT_MAXIMUM_FRACTION_DIGITS_NUMBER = 20;
export const DEFAULT_FIXED_PRICE_USD = 6;
export const DEFAULT_FIXED_PERCENT = 4;
