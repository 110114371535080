import React, { Suspense, Fragment, lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import MainIndex, { PropsLayout } from 'Screen/MainIndex';
import { IndexedObject } from 'types/common';
import { Epath } from './routesConfig';

export type RoutesProps = {
  exact?: boolean;
  path: string;
  component: React.FC<{ history: IndexedObject; location: IndexedObject; match: IndexedObject }>;
  auth?: boolean;
  routes?: Array<RoutesProps>;
  layout?: React.FC<PropsLayout>;
};

const RenderRoutes = ({
  routes,
  isAuthenticated,
}: {
  routes: Array<RoutesProps>;
  isAuthenticated: boolean;
}) => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        {routes.map((route, i) => {
          const LayoutRoute = route.layout || Fragment;
          const Component = route.component || <div />;
          if (!!route.auth && !isAuthenticated) {
            return (
              <Route
                key={i}
                path={route.path}
                element={<Navigate key={i} to={Epath.auth.login} replace />}
              />
            );
          }
          return (
            <Route
              key={i}
              path={route.path}
              element={
                <LayoutRoute>
                  {route.routes ? (
                    <RenderRoutes routes={route.routes} isAuthenticated={isAuthenticated} />
                  ) : (
                    <Component history={{}} location={{}} match={{}} />
                  )}
                </LayoutRoute>
              }
            />
          );
        })}
      </Routes>
    </Suspense>
  );
};

export const routes = [
  // {
  //   path: Epath.notFound,
  //   component: lazy(() => import('Screen/Auth/ErrorPage')),
  // },
  // {
  //   path: Epath.auth.login,
  //   component: lazy(() => import('Screen/Auth/SignIn')),
  // },
  // {
  //   path: Epath.auth.register,
  //   component: lazy(() => import('Screen/Auth/Signup')),
  // },
  // {
  //   path: Epath.auth.forgotPassword,
  //   component: lazy(() => import('Screen/Auth/ForgotPassword')),
  // },
  // {
  //   path: Epath.auth.resetPassword,
  //   component: lazy(() => import('Screen/Auth/ResetPassword')),
  // },
  // {
  //   path: Epath.auth.verification,
  //   component: lazy(() => import('Screen/Auth/Verification')),
  // },
  {
    path: Epath.top,
    component: lazy(() => import('Screen/TopPage/TopPage')),
  },
  // {
  //   path: Epath.tradingWebView,
  //   component: lazy(() => import('Screen/Exchange/TradingWebView')),
  // },
  // {
  //   path: Epath.exchange,
  //   component: lazy(() => import('Screen/Exchange/Exchange')),
  // },
  {
    path: '*',
    layout: MainIndex,
    component: () => <Navigate to={Epath.dashboard} />,
    routes: [
      // {
      //   path: Epath.dashboard,
      //   component: lazy(() => import('Screen/Dashboard/Dashboard')),
      //   auth: true,
      // },
      // {
      //   path: Epath.market,
      //   component: lazy(() => import('Screen/Market/Market')),
      // },
      // {
      //   path: Epath.walletPage,
      //   component: lazy(() => import('Screen/WalletPage/WalletPage')),
      //   auth: true,
      // },
      // {
      //   path: Epath.securityPage,
      //   component: lazy(() => import('Screen/SecurityPage/SecurityPage')),
      //   auth: true,
      // },
      // {
      //   path: Epath.profilePage,
      //   component: lazy(() => import('Screen/ProfilePage/ProfilePage')),
      //   auth: true,
      // },
      // {
      //   path: Epath.notificationsPage,
      //   component: lazy(() => import('Screen/Notifications/Notifications')),
      //   auth: true,
      // },
      {
        path: Epath.ourFeatures,
        component: lazy(() => import('Screen/OurFeatures/OurFeatures')),
      },
      {
        path: Epath.contactUs,
        component: lazy(() => import('Screen/ContactUs/ContactUs')),
      },
      {
        path: Epath.privacyPolicy,
        component: lazy(() => import('Screen/PrivacyPolicy/PrivacyPolicy')),
      },
      // {
      //   path: Epath.paymentSuccess,
      //   component: lazy(() => import('Screen/PaymentPage/PaymentSuccess')),
      //   auth: true,
      // },
      // {
      //   path: Epath.paymentCancel,
      //   component: lazy(() => import('Screen/PaymentPage/PaymentCancel')),
      //   auth: true,
      // },
      // {
      //   path: Epath.ico,
      //   component: lazy(() => import('Screen/ICO/Ico')),
      //   auth: true,
      // },
      // {
      //   path: Epath.future,
      //   component: lazy(() => import('Screen/Future/Future')),
      //   auth: true,
      // },
      // {
      //   path: Epath.p2p,
      //   component: lazy(() => import('Screen/P2P/P2pPage')),
      //   auth: true,
      // },
      // {
      //   path: Epath.battel,
      //   component: lazy(() => import('Screen/Battel/Battel')),
      //   auth: true,
      // },
      // {
      //   path: Epath.convert,
      //   component: lazy(() => import('Screen/Trade/ConvertTrade')),
      //   auth: true,
      // },
      // {
      //   path: Epath.marginTrade,
      //   component: lazy(() => import('Screen/Trade/MarginTrade')),
      //   auth: true,
      // },
      // {
      //   path: Epath.cryptoLoan,
      //   component: lazy(() => import('Screen/Finace/CryptoLoan')),
      //   auth: true,
      // },
      // {
      //   path: Epath.cryptoPay,
      //   component: lazy(() => import('Screen/Finace/CryptoPay')),
      //   auth: true,
      // },
      // {
      //   path: Epath.help,
      //   component: lazy(() => import('Screen/Help/Help')),
      //   auth: true,
      // },
      // {
      //   path: Epath.salarySlip,
      //   component: lazy(() => import('Screen/SalarySlip/SalarySlip')),
      //   auth: true,
      // },
      // {
      //   path: Epath.expenses,
      //   component: lazy(() => import('Screen/Expenses/Expenses')),
      //   auth: true,
      // },
      // {
      //   path: Epath.changelog,
      //   component: lazy(() => import('Screen/Changelog/Changelog')),
      //   auth: true,
      // },
      // {
      //   path: Epath.invoice,
      //   component: lazy(() => import('Screen/Invoice/Invoice')),
      //   auth: true,
      // },
      // {
      //   path: Epath.staterPage,
      //   component: lazy(() => import('Screen/Stater Page/StaterPage')),
      //   auth: true,
      // },
      // {
      //   path: Epath.rewardPage,
      //   component: lazy(() => import('Screen/Reward/Reward')),
      //   auth: true,
      // },
      // {
      //   path: Epath.identification,
      //   component: lazy(() => import('Screen/Identification/Identification')),
      //   auth: true,
      // },
      // {
      //   path: Epath.referPage,
      //   component: lazy(() => import('Screen/ReferalPage/ReferalPage')),
      //   auth: true,
      // },
      {
        path: '*',
        component: () => <Navigate to={Epath.top} />,
      },
    ],
  },
];

export default RenderRoutes;
