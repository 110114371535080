import axios from 'axios';
import { TActiveAccountRequest, TUpdateUserRequest } from 'Screen/Auth/slice/authTypes';
import { axiosClient } from '../axiosClient';
import queryString from 'query-string';

const userApi = {
  getCurrentUser: () => {
    const url = 'account/me';
    return axiosClient.get(url);
  },
  activeAccount: (params: TActiveAccountRequest) => {
    const url = 'activate';
    const query = queryString.stringify(params);
    return axiosClient.get(`${url}?${query}`);
  },
  updateCurrentUser: (data: TUpdateUserRequest) => {
    const url = 'account';
    return axiosClient.post(url, data);
  },
};

export default userApi;
