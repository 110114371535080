import { combineReducers } from 'redux';
import authReducer from 'Screen/Auth/slice/authSlice';
import orderReducer from 'Screen/Exchange/slice/orderSlice';
import marketReducer from 'Screen/Market/slice/marketSlice';
import { InjectedReducersType } from 'types/injector-typings';
import Mainreducer from './Mainreducer';
import toastReducer from './toastSlice';
import walletReducer from 'Screen/WalletPage/slice/walletSlice';
import notificationReducer from 'Screen/Notifications/slice/notificationSlice';

export const rootReducers = {
  main: Mainreducer,
  toast: toastReducer,
  auth: authReducer,
  market: marketReducer,
  order: orderReducer,
  wallet: walletReducer,
  notification: notificationReducer,
};

export function createReducer(injectedReducers: InjectedReducersType = {}) {
  // Initially we don't have any injectedReducers, so returning identity function to avoid the error
  if (Object.keys(injectedReducers).length === 0) {
    return (state: any) => state;
  } else {
    return combineReducers({
      ...injectedReducers,
    });
  }
}
