import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import marketApi from 'axiosApi/api/marketApi';
import {
  DEFAULT_PAGE,
  DEFAULT_SIZE,
  DEFAULT_TOTAL_RESULTS,
  defaultPagination,
} from 'helpers/common';
import { TCoinPair, TCoinTrend } from 'types/coin';
import { TOptionsQuery } from 'types/common';
import { TCryptoCurrency } from 'types/crypto';
import { RootState } from 'types/RootState';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { MarketState } from './marketTypes';

export const DEFAULT_SORT_MARKET = 'id,asc';
export const DEFAULT_SORT_CRYPTO_CURRENTCY = 'id,asc';

const initialState: MarketState = {
  params: {},
  pagination: defaultPagination,
  loadingMarket: false,
  loadingCryptoCurrencies: false,
  coinPairs: [],
  cryptoCurrencies: [],
  coinTrend: {},
  coinPair: {},
  coinMarketCap: [],
};

export const getCoinPairs = createAsyncThunk(
  'market/getCoinPairs',
  async (params: TOptionsQuery<TCoinPair>, { dispatch, getState }) => {
    dispatch(setParams(params));
    const state = (getState() as RootState).market;
    const {
      sort = state.params.sort || DEFAULT_SORT_MARKET,
      size = state.params.size || DEFAULT_SIZE,
      page = state.params.page || DEFAULT_PAGE,
    } = params ? params : {};
    let newParams: TOptionsQuery<TCoinPair> = Object.create(null);
    newParams.sort = sort;
    newParams.size = size;
    newParams.page = page - 1;
    const res = await marketApi.getCoinPairs({ ...params, ...newParams });
    return res;
  },
);

export const getCoinPair = createAsyncThunk(
  'market/getCoinPair',
  async (id: string, { dispatch }) => {
    const res = await marketApi.getCoinPair(id);
    return res.data;
  },
);

// Lock to use socket
export const getCoinTrend = createAsyncThunk('market/getCoinTrend', async (_, { dispatch }) => {
  const res = await marketApi.getCoinTrend();
  return res.data;
});

export const getCryptoCurrencies = createAsyncThunk(
  'market/getCryptoCurrencies',
  async (params: TOptionsQuery<TCryptoCurrency>, { dispatch, getState }) => {
    dispatch(setParams(params));
    const state = (getState() as RootState).market;
    const {
      sort = state.params.sort || DEFAULT_SORT_CRYPTO_CURRENTCY,
      size = state.params.size || DEFAULT_SIZE,
      page = state.params.page || DEFAULT_PAGE,
    } = params ? params : {};
    let newParams: TOptionsQuery<TCryptoCurrency> = Object.create(null);
    newParams.sort = sort;
    newParams.size = size;
    newParams.page = page - 1;
    const res = await marketApi.getCryptoCurrencies({ ...params, ...newParams });
    return res;
  },
);

const slice = createSlice({
  name: 'market',
  initialState,
  reducers: {
    setLoadingMarket(state, action: PayloadAction<boolean>) {
      state.loadingMarket = action.payload;
    },
    setParams(state, action: PayloadAction<TOptionsQuery<TCoinPair>>) {
      state.params = action.payload;
    },
    setCoinTrend(state, action: PayloadAction<TCoinTrend>) {
      state.coinTrend = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCoinPairs.pending, (state: MarketState) => {
      state.loadingMarket = true;
    });
    builder.addCase(getCoinPairs.rejected, (state: MarketState) => {
      state.loadingMarket = false;
    });
    builder.addCase(
      getCoinPairs.fulfilled,
      (state: MarketState, action: PayloadAction<AxiosResponse<TCoinPair[], any>>) => {
        const totalCount = action?.payload?.headers['x-total-count'];
        state.loadingMarket = false;
        state.coinPairs = action.payload.data;
        state.pagination = {
          page: state.params.page || DEFAULT_PAGE,
          size: state.params.size || DEFAULT_SIZE,
          totalResults: Number(totalCount) || DEFAULT_TOTAL_RESULTS,
        };
      },
    );

    builder.addCase(getCryptoCurrencies.pending, (state: MarketState) => {
      state.loadingMarket = true;
    });
    builder.addCase(getCryptoCurrencies.rejected, (state: MarketState) => {
      state.loadingMarket = false;
    });
    builder.addCase(
      getCryptoCurrencies.fulfilled,
      (state: MarketState, action: PayloadAction<AxiosResponse<TCryptoCurrency[], any>>) => {
        state.loadingCryptoCurrencies = false;
        state.cryptoCurrencies = action.payload.data;
      },
    );

    // getCoinPair
    builder.addCase(getCoinPair.pending, (state: MarketState) => {
      state.loadingMarket = true;
    });
    builder.addCase(getCoinPair.rejected, (state: MarketState) => {
      state.loadingMarket = false;
      state.coinPair = {};
    });
    builder.addCase(
      getCoinPair.fulfilled,
      (state: MarketState, action: PayloadAction<TCoinPair>) => {
        state.loadingMarket = false;
        state.coinPair = action.payload;
      },
    );

    // Lock to use socket
    // getCoinPairTrend
    builder.addCase(
      getCoinTrend.fulfilled,
      (state: MarketState, action: PayloadAction<TCoinTrend>) => {
        state.loadingMarket = false;
        state.coinTrend = action.payload;
      },
    );
  },
});

export const { actions, reducer: marketReducer } = slice;
export const { setLoadingMarket, setParams, setCoinTrend } = actions;
export default marketReducer;
