import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DEFAULT_TYPE_TOAST } from 'helpers/common';

export type TTypeToast = 'primary' | 'success' | 'danger';
export type TToast = {
  type: TTypeToast;
  id: number;
  message: string;
};
export type TAddToast = {
  message: string;
  type?: TTypeToast;
};

export type ToastState = {
  toasts: TToast[];
};

const initialState: ToastState = {
  toasts: [],
};

const slice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    addToast(state, action: PayloadAction<TAddToast>) {
      const { message, type = DEFAULT_TYPE_TOAST } = action.payload;
      state.toasts.push({
        id: new Date().getTime(),
        message,
        type,
      });
    },
    removeToast(state, action: PayloadAction<number>) {
      const id = action.payload;
      state.toasts = state.toasts.filter((toast) => toast.id !== id);
    },
  },
});

export const { actions, reducer: toastReducer } = slice;
export const { addToast, removeToast } = actions;
export default toastReducer;
