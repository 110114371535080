export const Epath = {
  notFound: '/404page',
  dashboard: '/dashboard',
  top: '/',
  auth: {
    login: '/sign-in',
    register: '/sign-up',
    forgotPassword: '/forgot-password',
    resetPassword: '/reset-password',
    verification: '/verification',
  },
  exchange: '/exchange/:id',
  market: '/market',
  ico: '/ico',
  future: '/future',
  p2p: '/p2p',
  battel: '/battel',
  convert: '/convert',
  marginTrade: '/margin-trade',
  cryptoLoan: '/crypto-loan',
  cryptoPay: '/crypto-pay',
  help: '/help',
  salarySlip: '/salary-slip',
  expenses: '/expenses',
  changelog: '/changelog',
  invoice: '/invoice',
  staterPage: '/stater-page',
  walletPage: '/wallet-page',
  rewardPage: '/reward-page',
  securityPage: '/security-page',
  identification: '/identification',
  referPage: '/refer-page',
  profilePage: '/profile-page',
  notificationsPage: '/notifications',
  tradingWebView: '/tradingView',
  ourFeatures: '/our-features',
  contactUs: '/contact-us',
  privacyPolicy: '/privacy-policy',
  paymentSuccess: '/payment-success',
  paymentCancel: '/payment-cancel',
};
