import CustomIcon from 'Component/Icons/CustomIcon/CustomIcon';
import LogoHeader from 'Component/Logo/LogoHeader';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { IndexedObject } from 'types/common';
import menuData from 'Component/Data/Menu/MenuTest.json';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import useOutsideClick from 'helpers/customHook/useOutsideClick';
import { RootState } from 'types/RootState';
import { useSelector } from 'react-redux';

type Props = {
  refSidebar: React.MutableRefObject<HTMLDivElement | null>;
  refMain: React.MutableRefObject<HTMLDivElement | null>;
};

function Sidebar({ refSidebar, refMain }: Props) {
  const { t } = useTranslation();
  const [isSidebarMini, setIsSidebarMini] = useState(false);
  const { authenticated } = useSelector((state: RootState) => state.auth);
  let sidebar = document.getElementById('mainsidemenu');

  useOutsideClick(refSidebar, refMain, () => {
    if (sidebar && sidebar.classList.contains('open')) {
      sidebar.classList.remove('open');
    }
  });

  const openChildren = (id: any) => {
    let otherTabs = document.getElementsByClassName('has-children');
    if (otherTabs) {
      for (let i = 0; i < otherTabs.length; i++) {
        if (otherTabs[i].id !== id) {
          otherTabs[i].className = otherTabs[i].className.replace(' show', '');
          if (Number(otherTabs[i].parentElement?.children.length) > 1) {
            otherTabs[i].parentElement?.children[0].setAttribute('aria-expanded', 'false');
          }
        }
      }
    }
    let menutab = document.getElementById(id);
    if (menutab) {
      if (menutab.classList.contains('show')) {
        menutab.classList.remove('show');
        if (Number(menutab.parentElement?.children.length) > 1) {
          menutab.parentElement?.children[0].setAttribute('aria-expanded', 'false');
        }
      } else {
        menutab.classList.add('show');
        if (Number(menutab.parentElement?.children.length) > 1) {
          menutab.parentElement?.children[0].setAttribute('aria-expanded', 'true');
        }
      }
    }
  };
  const openChildren1 = (id: any) => {
    let otherTabs = document.getElementsByClassName('has-children');
    if (otherTabs) {
      for (let i = 0; i < otherTabs.length; i++) {
        otherTabs[i].className = otherTabs[i].className.replace(' show', '');
      }
    }
    let menutab = document.getElementById(id);
    if (menutab) {
      menutab.classList.add('show');
      if (Number(menutab.parentElement?.children.length) > 1) {
        menutab.parentElement?.children[0].setAttribute('aria-expanded', 'true');
      }
    }
  };

  return (
    <div
      ref={refSidebar}
      id="mainsidemenu"
      className={`sidebar py-2 py-md-2 me-0 border-end ${isSidebarMini ? 'sidebar-mini' : ''}`}
    >
      <div className="d-flex flex-column h-100">
        {/* <LogoHeader height={isSidebarMini ? 24 : 52} /> */}
        <ul className="menu-list flex-grow-1 mt-4 px-1">
          {menuData.menu
            .filter((m) => !!authenticated || !!m.auth === !!authenticated)
            .map((d: IndexedObject, i: number) => {
              if (d.children.length === 0) {
                return (
                  <li key={'dsfshsdg' + i} className=" collapsed">
                    <NavLink to={'/' + d.routerLink[0]} className="m-link">
                      <CustomIcon object={d} />
                      <div>
                        <h6 className="mb-0">
                          {t(
                            translations.COMPONENTS.SIDE_BAR[
                              d.key as keyof typeof translations.COMPONENTS.SIDE_BAR
                            ]?.TITLE,
                          )}
                        </h6>
                        <small className="text-muted">
                          {t(
                            translations.COMPONENTS.SIDE_BAR[
                              d.key as keyof typeof translations.COMPONENTS.SIDE_BAR
                            ]?.SUB_TITLE,
                          )}
                        </small>
                      </div>
                    </NavLink>
                  </li>
                );
              }
              return (
                <li key={'shsdg' + i} className=" collapsed">
                  <Link
                    className="m-link"
                    to="#!"
                    onClick={(e) => {
                      e.preventDefault();
                      openChildren('menu-Pages' + i);
                    }}
                  >
                    <CustomIcon object={d} />
                    <div>
                      <h6 className="mb-0">
                        {t(
                          translations.COMPONENTS.SIDE_BAR[
                            d.key as keyof typeof translations.COMPONENTS.SIDE_BAR
                          ]?.TITLE,
                        )}
                      </h6>
                      <small className="text-muted">
                        {t(
                          translations.COMPONENTS.SIDE_BAR[
                            d.key as keyof typeof translations.COMPONENTS.SIDE_BAR
                          ]?.SUB_TITLE,
                        )}
                      </small>
                    </div>
                    <span className="arrow icofont-dotted-down ms-auto text-end fs-5"></span>
                  </Link>

                  {d.children.length > 0 ? (
                    <ul className="sub-menu collapse has-children" id={'menu-Pages' + i}>
                      {d.children.map((data: any, ind: any) => {
                        return (
                          <li key={'jfdgj' + ind}>
                            <NavLink className="ms-link" to={'/' + data.routerLink[0]}>
                              <span>
                                {t(
                                  translations.COMPONENTS.SIDE_BAR[
                                    data.key as keyof typeof translations.COMPONENTS.SIDE_BAR
                                  ]?.TITLE,
                                )}
                              </span>
                            </NavLink>
                          </li>
                        );
                      })}
                    </ul>
                  ) : null}
                </li>
              );
            })}
        </ul>
        <button
          type="button"
          className="btn btn-link sidebar-mini-btn text-muted"
          onClick={() => {
            if (sidebar && sidebar.classList.contains('open')) {
              sidebar.classList.remove('open');
            } else {
              setIsSidebarMini(!isSidebarMini);
            }
          }}
        >
          <span className="ms-2">
            <i className="icofont-bubble-right"></i>
          </span>
        </button>
      </div>
    </div>
  );
}

export default Sidebar;
