import notiApi from 'axiosApi/api/notiApi';
import { messageFirebase } from './firebase/lib';
import {
  getFcmToken,
  getTokenFromLocalStorage,
  getUserFromLocalStorage,
  removeFcmToken,
  saveFcmToken,
} from './localStorage';

const getTokenMessaging = async () => {
  return messageFirebase
    ? await messageFirebase
        .getToken()
        .then(async (fcmTokenFirebase) => {
          const currentFcmToken = getFcmToken();
          const jwtToken = getTokenFromLocalStorage();
          if (
            jwtToken &&
            (!currentFcmToken || (currentFcmToken && fcmTokenFirebase !== currentFcmToken))
          ) {
            await notiApi
              .changeFcmToken({ delete: false, fcmToken: fcmTokenFirebase })
              .then(() => {
                saveFcmToken(fcmTokenFirebase);
              })
              .catch(() => {
                removeFcmToken();
              });
          }
        })
        .catch(() => {})
    : '';
};

// Check Notification in browser
export const checkFcmToken = async () => {
  if (!('Notification' in window)) {
    alert('This browser does not support notifications!');
  } else if (Notification.permission === 'granted') {
    await getTokenMessaging();
  } else if (Notification.permission !== 'denied') {
    await Notification.requestPermission().then(async (permission) => {
      if (permission === 'granted') {
        await getTokenMessaging();
      }
    });
  }
};
