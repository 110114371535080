import React from 'react';
import { IndexedObject } from 'types/common';

type Props = {
  object: IndexedObject;
};

const CustomIcon = ({ object }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="24px"
      height="24px"
      viewBox={object.viewBox}
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d={object.iconClasss}
        style={{ fill: 'var(--primary-color)' }}
        data-st="fill:var(--chart-color4);"
      ></path>
      <path xmlns="http://www.w3.org/2000/svg" className="st0" d={object.iconClass}></path>
      {!!object.iconClas && (
        <path xmlns="http://www.w3.org/2000/svg" className="st0" d={object.iconClas}></path>
      )}
    </svg>
  );
};

export default CustomIcon;
