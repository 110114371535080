import { axiosClient } from 'axiosApi/axiosClient';
import { TCreateOrder, TGetOrdersBook } from 'Screen/Exchange/slice/orderTypes';
import { TOptionsQuery } from 'types/common';
import { TOrder } from 'types/order';
import queryString from 'query-string';

const orderApi = {
  getOrders: (params: TOptionsQuery<TOrder>) => {
    const url = 'orders/me';
    const query = queryString.stringify(params);
    return axiosClient.get(`${url}?${query}`);
  },
  createOrder: (data: TCreateOrder) => {
    const url = 'ordersExtend';
    return axiosClient.post(url, data);
  },
  deleteOrder: (id: number) => {
    const url = `ordersExtend/cancel/${id}`;
    return axiosClient.put(url);
  },
  getOrderBook: (params: TGetOrdersBook) => {
    const url = 'orders-book';
    const query = queryString.stringify(params);
    return axiosClient.get(`${url}?${query}`);
  },
};

export default orderApi;
