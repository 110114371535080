import firebase from 'firebase/app';
import '@firebase/messaging';
import 'firebase/auth';
import { FIRE_BASE_CONFIG } from 'helpers/const/firebase';

if (!firebase.apps.length) {
  firebase.initializeApp(FIRE_BASE_CONFIG);
}
export const messageFirebase = firebase.messaging.isSupported() ? firebase.messaging() : null;
export default firebase;
